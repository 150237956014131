<template>
  <v-card width="100%">
    <v-text-field class="px-5" v-model="search" append-icon="mdi-magnify" label="Buscar estudiante" single-line hide-details></v-text-field>
    <v-data-table height="300px" fixed-header :search="search" :headers="headers_students" :items="reply" item-key="id" class="m-3 elevation-1">
      <template v-slot:no-data>
        <p>Nadie ha realizado la actividad.</p>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="showDetails(item)">
          <v-icon color="primary" outlined >mdi-eye</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.timeR="{ item }">
        {{ getTimeRead(item.student.id) }}
      </template>
      <template v-slot:item.timeA="{ item }">
        {{ getTimeAnswer(item.student.id) }}
      </template>
      <template v-slot:item.percentage="{ item }">
        {{ getPercentage(item.points) }}
      </template>
    </v-data-table>

    <StudentReply v-if="showModal" :minigameName="minigame" :idEstudiante="currentStudent.studentId" :idActividad="currentStudent.activityId" :modalOpen.sync="showModal"></StudentReply>
  </v-card>
</template>

<script>
import StudentReply from './StudentReply.vue';

export default {
  props: {
    reply: {
      type: Array,
      required: true
    },
    totalPoints: {
      type: Number,
      required: true
    },
    timesR: {
      type: Array,
      required: true
    },
    timesA: {
      type: Array,
      required: true
    },
    minigame: {
      type: String && null,
      required: true
    }
  },
  components: { StudentReply },
  data() {
    return {
      search: '',
      showModal: false,
      currentStudent: null,
      headers_students: [
        { text: 'Nombre', value: 'student.name', sortable: false },
        { text: 'Apellido', value: 'student.lastName', sortable: false },
        { text: 'Lectura', value: 'timeR', sortable: false  },
        { text: 'Respuesta', value: 'timeA', sortable: false  },
        { text: 'Puntos', value: 'points', sortable: false  },
        { text: 'Nota %', value: 'percentage', sortable: false  },
        { text: 'Ver datos', value: 'actions', sortable: false }
      ],
    };
  },
  methods: {
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = (seconds % 60).toFixed(1);
      return minutes > 0
        ? `${minutes}m ${remainingSeconds}s`
        : `${remainingSeconds}s`;
    },
    showDetails(item) {
      console.log(this.minigame);
      this.currentStudent = item;
      this.showModal = true;
    },
    getTimeRead(idStudent) {
      const foundItem = this.timesR.find(item => item.idStudent === idStudent);
      return foundItem ? this.formatTime(foundItem.time) : 'N/A';
    },
    getTimeAnswer(idStudent) {
      const foundItem = this.timesA.find(item => item.idStudent === idStudent);
      return foundItem ? this.formatTime(foundItem.time) : 'N/A';
    },
    getPercentage(points) {
      return this.totalPoints ? ((points / this.totalPoints) * 100).toFixed(2) + "%" : "0%";
    }
  },
};
</script>