<template>
  <div>
    <v-tabs v-model="tab" class="mt-7">
      <v-tab v-for="(_, i) in questions" :href="`#${i}`" :key="i">Pregunta {{ i + 1 }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(question, index) in questions" :key="index" :value="`${index}`">
        <v-card :key="index" class="mt-4" width="100vw" height="100%" v-if="!loading">
          <v-card-title>
            <p v-if="finish">
              Puntaje Obtenido: {{ points }}/{{ totalPoints }}
            </p>
          </v-card-title>
          <v-form @submit.prevent="submit(true)">
            <v-card-text>
              <div class="text--primary">
                <p class="text-justify">{{ question.question.title }}</p>
                <p class="text-justify" v-if="question.question.description !== ''">
                  {{ question.question.description }}
                </p>
              </div>

              <v-img v-if="question.question.image" height="100%" width="50%" :src="question.question.image"></v-img>

              <v-container fluid v-if="question.question.type === 0">
                <v-textarea 
                  :disabled="finish" 
                  v-model="answers[index].answer" 
                  background-color="light-blue lighten-4"
                  color="teal"
                  @input="saveState"
                >
                  <template v-slot:label>
                    <div>Respuesta:</div>
                  </template>
                </v-textarea>
              </v-container>

              <v-container fluid v-if="question.question.type === 1">
                <v-radio-group v-model="answers[index].answer" @change="saveState">
                  <v-radio :disabled="finish" v-for="(alternative, i) in question.question.answer" :key="i"
                    :label="alternative.description" :value="alternative">
                    {{ alternative }}
                    <template v-slot:label>
                      <h4 v-if="!finish" class="text-left">
                        {{ alternative.description }}
                      </h4>
                      <h4 v-if="finish && alternative.isCorrect" class="text-left" style="color: green">
                        {{ alternative.description }} (Respuesta Correcta )
                      </h4>
                      <h4 v-else-if="finish && !alternative.isCorrect" class="text-left" :style="{
                        color:
                          alternative.id === answers[index].answer.id
                            ? 'red'
                            : '',
                      }">
                        {{ alternative.description }}
                      </h4>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-container>

              <v-container fluid v-if="question.question.type === 2">
                <v-checkbox 
                  :disabled="finish" 
                  v-for="(alternative, i) in question.question.answer" 
                  :key="i"
                  v-model="answers[index].answer" 
                  :label="alternative.description" 
                  :value="alternative"
                  @change="saveState"
                >
                  <template v-slot:label>
                    <h4 v-if="!finish" class="text-left">
                      {{ alternative.description }}
                    </h4>
                    <h4 v-if="finish && alternative.isCorrect" class="text-left" style="color: green">
                      {{ alternative.description }} (Respuesta Correcta )
                    </h4>
                    <h4 v-else-if="finish && !alternative.isCorrect" class="text-left" :style="{
                      color: answers[index].answer.some(
                        (val) => val.id === alternative.id
                      )
                        ? 'red'
                        : '',
                    }">
                      {{ alternative.description }}
                    </h4>
                  </template>
                </v-checkbox>
              </v-container>

              <p class="text-justify">
                Puntaje Pregunta: {{ question.question.points }} puntos.
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn v-if="tab !== '0'" @click="tab = `${parseInt(tab) - 1}`" text color="deep-purple accent-4">
                Anterior
              </v-btn>
              <v-btn v-if="tab !== `${questions.length - 1}`" @click="tab = `${parseInt(tab) + 1}`" text
                color="deep-purple accent-4">
                Siguiente
              </v-btn>
              <p v-if="!canSendForm && tab === `${questions.length - 1}`" class="text-justify mt-4" style="color: red">
                Debe responder todas las preguntas para enviar formulario. {{ questions.length}} {{ finish  }} {{ canSendForm }}
              </p>
              <v-btn v-if="
                tab === `${questions.length - 1}` && !finish && canSendForm
              " @click="submit" text color="deep-purple accent-4">
                Terminar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: {
    id: {
      type: Number,
    },
    time: {
      type: Number,
    },
    paragraphQuestions: {
      type: Array,
    },
    order: {
      type: Array,
      default: () => []
    },
    highLights: {
      type: Array,
    },
    words: {
      type: Array,
    },
    forceFinish: {
      type: Boolean,
      default: false
    },
    activity: {
      type: Object
    },
    minigame: {
      type: Object,
    }
  },
  data() {
    return {
      tab: "tab-1",
      answers: [],
      questions: [],
      finish: false,
      loading: false,
      points: 0,
      totalPoints: 0,
      timeForQuestion: 0,
      timeSpentPerQuestion: {},
      currentQuestionStartTime: null,
    };
  },
  watch: {
    forceFinish(newV) {
      if (newV) this.submit(false);
    },
    tab(newValue, oldValue) {
      if (oldValue !== null) { // Si no es la primera carga de la pestaña
        const now = new Date().getTime();
        const timeSpent = now - this.currentQuestionStartTime;
        const oldQuestionId = this.questions[oldValue].question.id;
        if (this.timeSpentPerQuestion[oldQuestionId] === undefined) {
          this.timeSpentPerQuestion[oldQuestionId] = 0;
        }
        this.timeSpentPerQuestion[oldQuestionId] += timeSpent;
      }
      this.currentQuestionStartTime = new Date().getTime(); // Reiniciar el contador de tiempo para la nueva pregunta
      this.saveState();
    }
  },
  computed: {
    canSendForm() {
      
      return !this.answers.some((val) => {
        if ((val.type === 0 && val.answer.description === "") || (val.type === 1 && val.answer === null) || (val.type === 2 && val.answer.length === 0)) {
          return true;
        }
      });
    },  
  },
  methods: {
    getIcon(alternative, answer) {
      if (alternative.id === answer.id && alternative.isCorrect) {
        return "mdi-check-circle";
      }
      if (alternative.id === answer.id && !alternative.isCorrect) {
        return "mdi-close-circle";
      }
      if (alternative.id !== answer.id) {
        return "mdi-checkbox-blank-circle-outline";
      }
    },
    checkPoints(answers) {
      let multiple = [];
      this.questions.forEach((element) => {
        if (element.question.type === 2) {
          let sum = element.question.answer.reduce((acc, el) => {
            let i = el.isCorrect ? 1 : 0;
            return acc + i;
          }, 0);

          multiple.push({
            id: element.question.id,
            total: sum,
          });
        }
      });

      answers.forEach((answer) => {
        if (answer.type === 1 && answer.answer.isCorrect)
          this.points += answer.points;
        if (answer.type === 2) {
          let index = multiple.find((el) => el.id === answer.idQuestion);
          let s = answer.answer.reduce(
            (acc, el) => acc + (el.isCorrect ? 1 : 0),
            0
          );

          if (answer.answer.some((element) => !element.isCorrect)) return;
          if (s !== index.total) return;
          else this.points += answer.points;
        }
      });
    },
    async submit(statement, order = false) {
      //Tiempo de la ultima pregunta
      const now = new Date().getTime();
      const timeSpent = now - this.currentQuestionStartTime;
      const questionId = this.questions[this.tab].question.id;
      if (this.timeSpentPerQuestion[questionId] === undefined) {
        this.timeSpentPerQuestion[questionId] = 0;
      }
      this.timeSpentPerQuestion[questionId] += timeSpent;

      let id = this.$route.params.id;
      if (statement && !order) {
        await Swal.fire({
          title: "¿Desea terminar la evaluación?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí!",
          cancelButtonText: "Volver",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await Swal.fire(
              "Completada!",
              "Las respuestas han sido contestadas",
              "success"
            );
            this.clearLocalStorage(id);
            this.checkPoints(this.answers);
            this.tab = 0;
            await axios.post("student/reply", {
              time: this.time,
              answer: JSON.stringify(this.answers),
              question: JSON.stringify(this.paragraphQuestions),
              activityId: id,
              points: this.points,
              highLight: JSON.stringify(this.highLights),
              saveWord: JSON.stringify(this.words),
              order: JSON.stringify(this.order),
              timeAnswer: JSON.stringify(this.questions.map(question => ({ id: question.question.id, time: this.timeSpentPerQuestion[question.question.id] / 1000}))),
              minigame_info: this.minigame ? JSON.stringify(this.minigame) : null
            });
            this.finish = true;

            await this.$router.push('/estudiante/finalizada/' + id);
          }
        });
      } else {
        await Swal.fire({
          title: order ? 'Se ah acabado el tiempo, enviando respuestas.' : "Tiempo de lectura excedido",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.checkPoints(this.answers);
            this.tab = 0;
            await axios.post("student/reply", {
              time: this.time,
              answer: JSON.stringify(this.answers),
              question: JSON.stringify(this.paragraphQuestions),
              activityId: id,
              points: this.points,
              highLight: JSON.stringify(this.highLights),
              saveWord: JSON.stringify(this.words),
              order: JSON.stringify(this.order),
              timeAnswer: JSON.stringify(this.questions.map(question => ({ id: question.question.id, time: this.timeSpentPerQuestion[question.question.id] / 1000}))),
              minigame_info: this.minigame ? JSON.stringify(this.minigame) : null
            });
            this.clearLocalStorage(id);
            this.finish = true;

            await this.$router.push('/estudiante/finalizada/' + id);

          }
        });
      }

    },
    saveState() {
      const state = {
        answers: this.answers,
        timeSpentPerQuestion: this.timeSpentPerQuestion,
        tab: this.tab,
        finish: this.finish
      };
      localStorage.setItem('student_activity_state_' + this.id, JSON.stringify(state));
    },
    loadState() {
      const state = JSON.parse(localStorage.getItem('student_activity_state_' + this.id));
      if (state) {
        this.answers = state.answers;
        this.timeSpentPerQuestion = state.timeSpentPerQuestion;
        this.tab = state.tab;
        this.finish = state.finish;
      }
    },
    clearLocalStorage(id) {
      console.log('clearing local storage' + id);

      localStorage.removeItem('student_activity_state_' + id);
      localStorage.removeItem('activity_time_' + id);
      localStorage.removeItem('activity_state_' + id);
      console.log('clearing local storage finalizadooo' + id);
    },
  },
  async created() {
    this.loading = true;
    this.loadState(); // Cargar el estado de la actividad
    try {

      const { questions } = await this.activity;
      this.questions = questions;
      this.questions
        .map(async (questions) => {
          this.totalPoints += questions.question.points;
          if (!(this.answers.find((answer) => answer.idQuestion === questions.question.id))) {
            if (questions.question.type === 0) {
              this.answers.push({
                idQuestion: questions.question.id,
                type: 0,
                answer: "",
                points: questions.question.points,
              });
            }
            if (questions.question.type === 1) {
              this.answers.push({
                idQuestion: questions.question.id,
                type: 1,
                answer: null,
                points: questions.question.points,
              });
            }
            if (questions.question.type === 2) {
              this.answers.push({
                idQuestion: questions.question.id,
                type: 2,
                answer: [],
                points: questions.question.points,
              });
            }
          }
          
        });
        this.loading = false;
        this.currentQuestionStartTime = new Date().getTime(); // Iniciar el contador de tiempo
      if (this.questions.length === 0) this.submit(true, true);
      if (this.forceFinish) this.submit(false);

    } catch (e) {
      console.log(e);
    }
  },
};
</script>