<template>
  <v-dialog v-model="dialog" persistent max-width="80%" style="overflow-y: hidden;">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="my-4 responsive-button" height="80px" width="90%" color="primary" v-bind="attrs" v-on="on">
        Editar usuarios
      </v-btn>
    </template>

    <v-container fluid>
      <v-card>
        <div class="d-flex align-center justify-space-between primary white--text">
          <v-card-title primary white--text>
            Selecciona un usuario
          </v-card-title>
          <v-btn class="mr-5 text-white " color="red" @click="closeDialog"> X </v-btn>
        </div>
        
        <v-text-field class="mx-4 " v-model="search" append-icon="mdi-magnify" label="Buscar Usuario" single-line hide-details>
          
        </v-text-field>
        <v-data-table height="400px" class="pa-4" :headers="headers" :items="formattedUsers" :search="search">
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.rol }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.lastName }}</td>
              <td>{{ item.email }}</td>
              <td >
                <v-btn class="bg-black" icon @click="item.editButton.action()">
                  <v-icon>{{ item.editButton.icon }}</v-icon>
                </v-btn>
              </td>
              <td >
                <v-btn icon @click="item.deleteButton.action()">
                  <v-icon>{{ item.deleteButton.icon }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>


      <v-dialog v-model="confirmDeleteDialog" max-width="400px">
        <v-card>
          <v-card-title class="text-h6 mb-2">¿Estás seguro de que deseas eliminar este usuario?</v-card-title>
          <v-card-actions>
            <v-btn class="ml-6" color="red" @click="cancelDelete">No</v-btn>
            <v-spacer></v-spacer>
            <v-btn class="mr-6" color="primary"  @click="deleteUserConfirmed">Sí</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="editDialog" max-width="600px">
        <v-card>
          <v-card-title>Editar usuario</v-card-title>
          <v-card-text>
            <v-form ref="form" lazy-validation>
              <v-text-field v-model="form.name" label="Nombre"></v-text-field>
              <v-text-field v-model="form.lastName" label="Primer Apellido"></v-text-field>
              <v-text-field v-model="form.secondLastName" label="Segundo Apellido"></v-text-field>
              <v-text-field v-model="form.email" label="Email"></v-text-field>
              <v-checkbox v-if="selectedUser && selectedUser.rol === 1" v-model="form.admin" label="Ser Administrador"></v-checkbox>

            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red" @click="closeEditDialog">Cerrar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary"  @click="submit" >Guardar cambios</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar class="text-center" :color="snackbarColor" v-model="snackbar">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> OK </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
      dialog: false,
      editDialog: false,
      confirmDeleteDialog: false,
      search: "",
      users: [],
      headers: [
        { text: "Rol", value: "rol" },
        { text: "Nombre", value: "name" },
        { text: "Apellido", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Editar", value: "edit", sortable: false },
        { text: "Eliminar", value: "delete", sortable: false },
      ],
      selectedUser: null,
      form: {
        name: "",
        lastName: "",
        email: "",
      },
    };
  },

  computed: {
    formattedUsers() {
      return this.users.map(user => ({
        rol: user.rol === 1 ? 'Profesor' : 'Estudiante',
        name: user.name,
        lastName: user.lastName,
        email: user.email,
        editButton: { text: 'Editar', action: () => this.editUser(user), icon: 'mdi-pencil' },
        deleteButton: { text: 'Eliminar', action: () => this.openConfirmDeleteDialog(user), icon: 'mdi-delete' },
      }));
    },

    selectedUserId() {
      return this.selectedUser ? this.selectedUser.id : null;
    },
  },

  methods: {
    async getUsers() {
      try {
        const response = await axios.get("teacher/getUsers");
        
        this.users = response.data.users;
      } catch (error) {
        console.error("Error al obtener usuarios:", error);
      }
    },

    openConfirmDeleteDialog(user) {
      this.selectedUser = user;
      this.confirmDeleteDialog = true;
    },

    async deleteUserConfirmed() {
      if (this.selectedUser) {
        await this.deleteUser(this.selectedUser);
        this.confirmDeleteDialog = false;
        this.showSnackbar("Usuario eliminado con éxito", "green");
      }
    },

    async deleteUser(user) {
      try {
        const id = user.id;
        console.log(id)
        await axios.delete(`teacher/deleteUser/${id}`);
        this.users.splice(this.users.indexOf(user), 1);
      } catch (error) {
        console.error("Error al eliminar usuario:", error);
      }
    },

    editUser(user) {
      this.selectedUser = user;
      this.form = {
        name: user.name,
        lastName: user.lastName,
        secondLastName:user.secondLastName,
        email: user.email,
        admin: user.admin
      };
      this.editDialog = true;
    },

    async submit() {
      const userId = this.selectedUserId;

      if (userId) {
        try {
          await axios.put(`teacher/updateUser/${userId}`, this.form);
          this.editDialog = false;
          this.getUsers();
          this.showSnackbar("Usuario actualizado con éxito", "green");
        } catch (error) {
          console.error("Error al actualizar usuario:", error);
          this.showSnackbar("Error al actualizar usuario", "red");
        }
      }

      this.resetForm();
    },

    showSnackbar(text, color) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },

    cancelDelete() {
      this.selectedUser = null;
      this.confirmDeleteDialog = false;
    },

    closeEditDialog() {
      this.editDialog = false;
      this.resetForm();
    },

    closeDialog() {
      this.dialog = false;
    },

    cancel() {
      this.editDialog = false;
      this.resetForm();
    },

    resetForm() {
      this.form = {
        name: "",
        lastName: "",
        email: "",
      };
    },
  },

  created() {
    this.getUsers();
  },
};
</script>

<style scoped>
  @media (max-width: 930px) {
    .responsive-button {
      font-size: 8px;
    }
  }

  @media (min-width: 930px) and (max-width: 1200px) {
    .responsive-button {
      font-size: 12px;
    }
  }

  @media (min-width: 1200px) {
    .responsive-button {
      font-size: 14px;
    }
  }
</style>