<template>
  <v-dialog v-model="showModal" persistent max-width="650px">
    <v-card>
      <v-card-title>
        <h3>{{ modalTitle }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:showModal', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <!-- Lista de respuestas -->
        <ul v-if="modalType === 'alternative'">
          <li v-for="response in filteredResponses" :key="response.id">
            <!-- Mostrar el nombre del alumno y su respuesta -->
            <span>{{ response.student }} </span>
          </li>
        </ul>
        <ul v-else-if="modalType === 'development'">
          <div class="d-flex align-center" v-for="response in filteredResponses" :key="response.id">
            <!-- Mostrar el nombre del alumno y su respuesta -->
            <span class="mr-5">{{ response.student }} </span>
            <span class="ml-3"> Respuesta : {{ isPollActivity ? response.answer : response.answer[0].answer }} </span>

          </div>
        </ul> 
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    questionId: {
      type: Number,
      required: true
    },
    alternativeId: {
      type: Number
    },
    modalType: {
      type: String,
      required: true
    },
    replies: {
      type: Array,
      required: true
    },
    showModal: {
      type: Boolean,
      required: true
    },
    isPollActivity: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modalTitle: '',
      filteredResponses: []
    };
  },
  created() {
      this.filteredResponses = [];

      console.log(this.replies);

      this.filteredResponses = this.replies.map(reply => {
        if (this.modalType === 'alternative') {
          
          let parsedAnswer;
          if (typeof reply.answer === 'string') {
              try {
                  parsedAnswer = JSON.parse(reply.answer);
              } catch (e) {
                  console.error('Error parsing JSON:', e);
                  parsedAnswer = [];
              }
          } else {
              parsedAnswer = reply.answer;
          }

          parsedAnswer = Object.values(parsedAnswer)
          let filteredAnswer;
          if (this.isPollActivity) {

            filteredAnswer = parsedAnswer.filter(ans => ans.questionId === this.questionId);
            // Verificar el tipo de respuesta para cada elemento filtrado
            for (const ans of filteredAnswer) {
              if ( ans.answerId === this.alternativeId) {         
                  return { student: `${reply.student.name} ${reply.student.lastName}` };
              }
            }
          }else{
             filteredAnswer = parsedAnswer.filter(ans => ans.idQuestion === this.questionId);
             // Verificar el tipo de respuesta para cada elemento filtrado
              for (const ans of filteredAnswer) {
                if (ans.type === 1 && ans.answer.id === this.alternativeId) {
                  return { student: `${reply.student.name} ${reply.student.lastName}` };
                } else if (ans.type === 2) {
                  const alternative = ans.answer.find(a => a.id === this.alternativeId);
                  if (alternative !== undefined) {
                    return { student: `${reply.student.name} ${reply.student.lastName}` };
                  }
                }
              }
          }

          
        } else if (this.modalType === 'development') {
          let parsedAnswer;
          if (typeof reply.answer === 'string') {
              try {
                  parsedAnswer = JSON.parse(reply.answer);
              } catch (e) {
                  console.error('Error parsing JSON:', e);
                  parsedAnswer = [];
              }
          } else {
              parsedAnswer = reply.answer;
          }
          let filteredAnswer;
          parsedAnswer = Object.values(parsedAnswer)
          
          if (this.isPollActivity) {
            filteredAnswer = parsedAnswer.filter(ans => ans.questionId === this.questionId);

            // Verificar si hay respuestas para el tipo de desarrollo
            if (filteredAnswer.length > 0) {
              return { student: `${reply.student.name} ${reply.student.lastName}`, answer: filteredAnswer[0].answerText};
            }
            
          }else{
             filteredAnswer = parsedAnswer.filter(ans => ans.idQuestion === this.questionId);
             // Verificar si hay respuestas para el tipo de desarrollo
            if (filteredAnswer.length > 0) {
              return { student: `${reply.student.name} ${reply.student.lastName}`, answer: filteredAnswer};
            }
          }
          
        }
        return null;
      }).filter(Boolean);

      console.log(this.filteredResponses);

      // Establecer el título del modal
      if (this.modalType === 'alternative' && this.alternativeId !== undefined) {
        this.modalTitle = `Estudiantes que respondieron a la alternativa `;
      } else if (this.modalType === 'development') {
        this.modalTitle = 'Respuestas de desarrollo';
      }
    },
};
</script>

<style scoped>
/* Estilos para el modal */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
}
</style>