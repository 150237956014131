<template>
  <v-card outlined style="border: 0.2px solid #333;">
    <v-card-title class="d-flex justify-space-between primary white--text">
      <div>
        Diagnósticos parte de la asignatura
      </div>
      <div>
        <poll-activity-form @poll-activity-created="handlePollUpdated" class="justify-end" :lessonId="this.$route.params.id" />
      </div>
    </v-card-title>

    <v-text-field
      class="mx-4"
      v-model="search"
      append-icon="mdi-magnify"
      label="Buscar Diagnóstico"
      single-line
    ></v-text-field>

    <v-data-table
      height="350px"
      fixed-header
      :search="search"
      :headers="headers"
      :items="polls"
      item-key="id"
      class="m-3 elevation-1"
    >
      <template v-slot:no-data>
        <p>No hay diagnósticos.</p>
      </template>
      <template v-slot:item.state="{ item }">
        <v-chip dark :color="getStatusClass(item)" class="text-white">
          {{ new Date(item.limit) > new Date() ? 'Activo' : 'Terminado' }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn @click="openPollData(item)" color="primary" outlined small>
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-btn @click="confirmDelete(item)" color="red" outlined small>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <poll-activity-details
      :dialog="isDialogOpen"
      :poll-activity="selectedPoll"
      @close-dialog="closeDialogHandler"
    />
  </v-card>
</template>

<script>
import PollActivityForm from '../../../../components/teacher/forms/PollActivityForm.vue';
import PollActivityDetails from '../../../../components/teacher/PollActivityDetails.vue';
import axios from 'axios';
import Swal from "sweetalert2";

export default {
  components: { PollActivityForm, PollActivityDetails },
  data: () => ({
    color: 'success',
    snackbar: null,
    loading: false,
    search: '',
    headers: [
      {
        text: 'Id',
        align: 'center',
        value: 'id'
      },
      { text: 'Fecha de fin', value: 'limit' },
      { text: 'Estado', value: 'state', sortable: false },
      { text: 'Descripción', value: 'description' },
      { text: 'Ver datos', value: 'actions', sortable: false },
      { text: 'Eliminar', value: 'delete', sortable: false }
    ],
    selectedPoll: null,
    isDialogOpen: false
  }),

  computed: {
    polls() {
      return this.$store.getters['teacher/getPollsActivityInLesson'];
    }
  },

  created() {
    this.getPolls();
  },

  methods: {
    closeDialogHandler() {
      console.log('closeDialogHandler');
      console.log(this.isDialogOpen)
      this.selectedPoll = null;
      this.isDialogOpen = false;
    },
    async getPolls() {
      let id = this.$route.params.id;
      await this.$store.dispatch('teacher/pollActivitiesInLesson', id);
    },
    async confirmDelete(poll) {
      const result = await Swal.fire({
        title: '¿Está seguro de que desea eliminar esta actividad de diagnóstico?',
        text: "¡Esta acción no se puede deshacer!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      });

      if (result.isConfirmed) {
        this.deletePoll(poll);
      }
    },
    async deletePoll(poll) {
      try {
        await axios.delete(`/teacher/poll_activity/${poll.id}`);
        Swal.fire(
          'Eliminado',
          'El diagnóstico ha sido eliminado.',
          'success'
        );
        this.handlePollUpdated();
      } catch (error) {
        Swal.fire(
          'Error',
          'Hubo un problema al eliminar el diagnóstico.',
          'error'
        );
      }
    },
    async openPollData(poll) {
      this.selectedPoll = null;
      const response = await axios.get(`/teacher/poll_activity/${poll.id}`);
      this.selectedPoll = response.data.pollActivity;
      console.log(this.selectedPoll);
      this.isDialogOpen = true;
    },
    getStatusClass(item) {
      return new Date(item.limit) > new Date() ? 'blue' : 'red';
    },
    handlePollUpdated() {
      this.getPolls();
    }
  }
}
</script>