import Vuex from 'vuex';
import Vue from 'vue';
import teachersModule from './teacher/index';
import studentsModule from './student/index';
import authModule from './auth/index';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        user: {
            modules: {
                auth: authModule,
                teacher: teachersModule,
                student: studentsModule
            }
        },

    },


})

export default store;
