<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    chartBarData: {
      type: Object,
      default: null
    },
    chartBarOptions: {
      type: Object,
      default: null
    }
  },
  mounted() {
    this.renderChart(this.chartBarData, this.chartBarOptions);
  },
  methods: {
    updateChart(newData) {
      if (newData) {
        this.$data._chart.destroy(); // Destruye el gráfico existente
        this.renderChart(newData, this.chartBarOptions); // Renderiza el nuevo gráfico
      }
    }
  },
  watch: {
    chartBarData: {
      immediate: true,
      handler(newData) {
        this.updateChart(newData); // Llama al método para actualizar el gráfico
      }
    }
  }
};
</script>