<template>
  <div>
    <v-btn icon @click="dialog = true" class="large-btn">
      <v-icon large>mdi-information</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>
        <v-card-text v-html="message"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Información'
    },
    message: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: false
    };
  }
};
</script>

<style scoped>
.large-btn {
  width: 30px;
  height: 30px;
}
</style>