<template>
  <!-- Modal para compartir asignatura -->
  <v-dialog v-model="showShareModal" max-width="80%">
    <v-card>
      <v-card-title class="primary white--text d-flex justify-space-between">
        Compartir Asignatura
        <info-button class="mr-4" title="Información sobre compartir asignaturas" message="
          <ul>
            <li><strong>Selección de Profesores:</strong> Se muestran solo los profesores registrados en el sistema. Utilice el campo de búsqueda para encontrar rápidamente a los profesores.</li>
            <li><strong>Compartir:</strong> Seleccione uno o más profesores a los que desea compartir la asignatura y luego haga clic en el botón 'Compartir'.</li>
            <li><strong>Cancelar:</strong> Haga clic en el botón 'Cancelar' para cerrar el modal sin compartir la asignatura.</li>
            <li><strong>Permisos:</strong> Los profesores con los que comparta la asignatura tendrán los mismos permisos, incluyendo la capacidad de crear actividades con sus propios textos y diagnósticos. Sin embargo, podrán ver todos los datos y actividades creadas dentro de la asignatura compartida.</li>
          </ul>
        "></info-button>
      </v-card-title>
      <v-card-text>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar Profesores" single-line hide-details></v-text-field>
      </v-card-text>
      <v-data-table height="400" fixed-header :search="search" v-model="selectedTeachers" :headers="headers" :items="teachers" :single-select="singleSelect" item-key="id" show-select class="elevation-1">
      </v-data-table>
      <v-card-actions class="flex mt-1 justify-space-between">
        <v-btn @click="showShareModal = false" color="error">Cancelar</v-btn>
        <v-btn color="primary" @click="confirmShareLesson" :disabled="selectedTeachers.length === 0">Compartir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import InfoButton from "../../../../components/UI/InfoButton.vue";

export default {
  components: { InfoButton },
  props: ['showShareModal'],
  model: {
    prop: 'showShareModal',
    event: 'change',
  },
  watch: {
    showShareModal(newValue) {
      this.$emit('change', newValue);
    },
  },
  data() {
    return {
      search: '',
      singleSelect: false,
      teachers: [], // Obtén la lista de profesores disponibles desde tu fuente de datos
      selectedTeachers: [], // Almacena los profesores seleccionados para compartir
      headers: [
        {
          text: 'Nombre Profesor',
          align: 'start',
          value: 'name',
        },
        { text: 'Apellido Paterno', value: 'lastName' },
        { text: 'Apellido Materno', value: 'secondLastName' },
        { text: 'Email', value: 'email' },
        { button: 'Agregar' }
      ],
    };
  },
  methods: {
    async getTeachers() {
      const id = this.$route.params.id;
      const response = await axios.get('teacher/teachers/' + id);
      const { teacher } = response.data;
      this.teachers = teacher;
    },
    async confirmShareLesson() {
      const result = await Swal.fire({
        title: '¿Desea compartir esta asignatura?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, compartir',
        cancelButtonText: 'Cancelar'
      });

      if (result.isConfirmed) {
        this.shareLesson();
      }
    },
    async shareLesson() {
      let teachersIds = [];
      this.selectedTeachers.map(data => teachersIds.push(data.id));

      let data = {
        lessonId: this.$route.params.id,
        teacherId: teachersIds,
      };

      try {
        await axios.post('teacher/teacher_lesson', data);
        this.selectedTeachers = [];
        await this.getTeachers();
        this.showShareModal = false;
        Swal.fire('Compartido', 'La asignatura se compartió con éxito.', 'success');
      } catch (error) {
        Swal.fire('Error', 'Hubo un problema al compartir la asignatura. Inténtelo nuevamente.', 'error');
      }
    },
  },
  created() {
    this.getTeachers();
  }
};
</script>