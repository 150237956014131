<template>
  <div>

    <v-tabs v-model="tab">
      <v-tab href="#tab-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" text>
              Asignatura
            </v-btn>
          </template>
          <span>Detalles asignatura.</span>
        </v-tooltip>
      </v-tab>
      <v-tab href="#tab-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" text>
              Listado
            </v-btn>
          </template>
          <span>Muestra todos los alumnos inscritos en la asignatura.</span>
        </v-tooltip>
      </v-tab>
      <v-tab href="#tab-3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" text>
              Diagnósticos
            </v-btn>
          </template>
          <span>Muestra todos las diagnosticos parte de la asignatura.</span>
        </v-tooltip>
      </v-tab>
      <v-tab href="#tab-4">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" text>
              Actividades
            </v-btn>
          </template>
          <span>Muestra todos las actividades realizadas.</span>
        </v-tooltip>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-row fluid class="mt-8 justify-center mb-4">
          
          <v-card width="70%" class="mr-5">
            <lesson-info class="v-flex"/> 
          </v-card>
          <v-card width="20%" outlined style="border: 0.2px solid #333;" >
            <v-card-actions >
              <v-col class="pt-8">
                <assignment-form :lessonId="this.$route.params.id" />
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class=" responsive-button my-4" height="80px" width="90%" color="primary" @click="showShareModal = true"  @change="showShareModal = $event" >Compartir Asignatura</v-btn>
                  </template>   
                  <span>Compartir la asignatura con otros profesores dentro de la aplicación.</span>
                </v-tooltip>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-row>
      </v-tab-item>

      <v-tab-item  value="tab-2">
        <v-row class="d-flex justify-center mt-8">
          <v-card outlined style="border: 0.2px solid #333;" width="90%" >
            <current-students ></current-students>
          </v-card>
        </v-row>
          
        
      </v-tab-item>

      <v-tab-item   value="tab-3">
        <v-row class="d-flex justify-center px-8 mt-8">
          <v-col cols="12">
            <lesson-polls-tabla></lesson-polls-tabla>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item value="tab-4">
        <v-row class="d-flex justify-center align-center mt-8">
          <lesson-activities-table></lesson-activities-table>
        </v-row>
        </v-tab-item>
    </v-tabs-items>

    <lesson-share :showShareModal="showShareModal" @change="showShareModal = $event" ></lesson-share>

  </div>
</template>

<script>
import CurrentStudents from "./details/CurrentStudents";
import AssignmentForm from "../../../components/teacher/forms/AssignmentForm";
import LessonInfo from "./details/LessonInfo";
import LessonShare from './details/LessonShare';
import LessonActivitiesTable from './details/LessonActivitiesTable.vue';
import LessonPollsTabla from "./details/LessonPollsTabla.vue";
export default {
  components: { LessonInfo,LessonPollsTabla, CurrentStudents, AssignmentForm, LessonShare, LessonActivitiesTable },
  data() {
    return {
      lessonId: null,
      tab: null,
      showShareModal: false,
    }
  },
}
</script>


<style scoped>
  @media (max-width: 1300px) {
    .responsive-button {
      font-size: 8px;
    }
  }

  @media (min-width: 1300px) and (max-width: 1400px) {
    .responsive-button {
      font-size: 12px;
    }
  }

  @media (min-width: 1400px) {
    .responsive-button {
      font-size: 13px;
    }
  }
</style>