<template>
  <v-container fluid>
    <v-tabs v-model="activeTab">
      <v-tab href="#tab-1">Diagnósticos</v-tab>
      <v-tab href="#tab-2">Crear Diagnósticos</v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeTab">
      <v-tab-item class="mt-5 pa-6"  value="tab-1">
        <v-col v-if="!loading && pollsData.length === 0">
          <v-row class="d-flex justify-center image-container">
              <v-img
                class="contained-image"
                :src="require('@/assets/images/nodata.jpg')"
              ></v-img>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn class="pa-6 ma-3" @click="activeTab='tab-2'" color="primary" v-bind="attrs" v-on="on">
              Actualmente no posee diagnósticos creados, haga click para crear uno
            </v-btn>
          </v-row>
            
        </v-col>

        <v-row v-if="!loading && pollsData.length > 0">
          <v-autocomplete
              @change="toDetailsBar"
              :items="pollsData"
              item-text="title"
              outlined
              dense
              label="Buscar Diagnósticos"
              return-object
          ></v-autocomplete>
        </v-row>

        <v-row v-if="!loading && pollsData.length > 0" justify="center">
          <v-col v-for="(poll, index) in showableArray" :key="index" xl="3" lg="4" md="4" sm="12">
            <card-component
                :id="poll.id"
                :title="poll.title"
                :description="poll.description"
                image="lesson"
            >
              <template v-slot:activities>
                <v-btn
                class="mr-3"
                    color="success"
                    @click="toDetails(poll.id)"
                >
                  Ver Detalles
                </v-btn>
                <v-btn @click="confirmDelete(poll.id)" color="red" outlined >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <template v-slot:polls>
                
              </template>
            </card-component>
          </v-col>
        </v-row>

        <v-row  justify="center">
          <v-col class="d-flex justify-center align-center">
            <v-progress-circular
                v-if="loading"
                size="200"
                :width="3"
                color="primary"
                indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>

        <v-container fluid class="d-flex justify-center align-center" v-if="!loading && pollsData.length > 0">
          <v-btn
              v-for="(index, i) in paginate"
              :key="i"
              @click="current_page = index - 1"
              elevation="3"
              color="primary"
              fab
              style="margin-right: 10px"
          >
            {{ index }}
          </v-btn>
        </v-container>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <PollForm></PollForm>
        
      </v-tab-item>
    </v-tabs-items>
    

  </v-container>
</template>

<script>

import CardComponent from "../../../components/UI/CardComponent";
import PollForm from "../../../components/teacher/forms/PollForm.vue";
import Swal from "sweetalert2";
import axios from "axios";

export default {

  data () {
    return {
      loading: null,
      activeTab:1,
      items_per_page: 6,
      current_page: 0,
    }
  },

  components: { CardComponent,PollForm },
  computed: {
    pollsData() {
      return this.$store.getters['teacher/getPolls'];
    },
    paginate() {
      return  Math.ceil((this.pollsData.length / this.items_per_page));
      // largo del arreglo / items por pagin - redondea para arriba
    },
    showableArray() {
      return this.pollsData.slice(this.current_page*this.items_per_page, this.items_per_page + this.current_page*this.items_per_page);
    }
  },

  methods: {
    async getPolls() {
      this.loading = true;
      await this.$store.dispatch('teacher/poll');
      this.loading = false;
    },
    toDetails(id) {
      this.$router.push('/profe/diagnosticos/' + id);
    },
    toDetailsBar(lesson) {
      this.$router.push('/profe/diagnosticos/' + lesson.id);
    },
    async confirmDelete(poll_id) {
      const result = await Swal.fire({
        title: '¿Está seguro de que desea eliminar este de diagnóstico?',
        text: "¡Esta acción solo la puede realizar el creador del diagnostico y eliminara todas las actividades relacionadas!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      });

      if (result.isConfirmed) {
        this.deletePoll(poll_id);
      }
    },
    async deletePoll(poll_id) {
      try {
        const response = await axios.delete(`/teacher/poll/${poll_id}`);
        console.log('API Response:', response); // Log de la respuesta
        if (response.data.ok) {
          Swal.fire(
            'Eliminado',
            'El diagnóstico ha sido eliminado.',
            'success'
          );
          this.getPolls();
        } else {
          throw new Error(response.data.error || 'Hubo un problema al eliminar el diagnóstico.');
        }
      } catch (error) {
        console.error('Error al eliminar el diagnóstico:', error);
        if (error.response && error.response.status === 403) {
          Swal.fire(
            'No autorizado',
            'No tiene permiso para eliminar este diagnóstico.',
            'error'
          );
        } else {
          Swal.fire(
            'Error',
            error.message || 'Hubo un problema al eliminar el diagnóstico.',
            'error'
          );
        }
      }
    }
  },
  created() {
    this.getPolls();
  }
}
</script>

<style scoped>
.image-container {
  width: 100%;
  height: 500px; /* Set a fixed height for the image container */
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
}
.contained-image {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}
</style>
