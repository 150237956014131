<template>
  <v-row justify="center">
    <v-card class="mt-6" width="80%">
      <v-card-title class="primary white--text d-flex justify-space-between">
        Crear Nuevo Texto
        <info-button title="Información en la creación de un texto" message="
        <ul>
            <li><strong>Título:</strong> Ingrese el título del texto.</li>
            <li><strong>Autor:</strong> Especifique el autor del texto.</li>
            <li><strong>Fecha de Publicación:</strong> Proporcione la fecha de publicación del texto.</li>
            <li><strong>Fuente:</strong> Indique la fuente del texto.</li>
            <li><strong>Tipo de Texto:</strong> Seleccione el tipo de texto de la lista desplegable.</li>
            <li><strong>Agregar Párrafos:</strong> Introduzca el número de párrafos en el campo 'Número de párrafos'. Esto generará automáticamente áreas de texto donde podrá ingresar el contenido de cada párrafo.</li>
            <li><strong>Contenido:</strong> Complete cada área de texto generada con el contenido correspondiente de cada párrafo.</li>
            <li><strong>Descripción:</strong> Añada una breve descripción del texto.</li>
            <li><strong>Imagen:</strong> (Opcional) Adjunte una imagen para ser la portada del texto.</li>
          </ul>
        "></info-button>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-card class="v-card elevation-0 theme--light">
            <v-form @submit.prevent="submit" ref="form" lazy-validation>
              <v-text-field v-model="form.title" prepend-icon="mdi-format-title" label="Titulo"></v-text-field>
              <v-text-field v-model="form.author" prepend-icon="mdi-account-edit-outline" label="Autor"></v-text-field>
              <v-text-field v-model="form.publicationYear" prepend-icon="mdi-calendar" label="Fecha Publicación"></v-text-field>
              <v-text-field v-model="form.font" prepend-icon="mdi-alphabet-latin" label="Fuente"></v-text-field>
              <v-autocomplete v-model="form.type" :items="type" prepend-icon="mdi-format-text-variant-outline" label="Tipo de Texto" required></v-autocomplete>
              <v-text-field @change="paragraphNumber" type="number" v-model="form.counter" prepend-icon="mdi-counter" label="Número de párrafos"></v-text-field>
              <v-textarea v-for="(data, i) in form.content" :key="i" prepend-icon="mdi-content-copy" v-model="form.content[i]" auto-grow filled color="deep-purple" label="Contenido" rows="5"></v-textarea>
              <v-text-field v-model="form.description" prepend-icon="mdi-format-color-text" label="Descripción"></v-text-field>
              <v-file-input v-model="form.image" prepend-icon="mdi-image" label="Imagen (Opcional)" accept="image/*"></v-file-input>
              <v-card-actions>
                <v-btn color="error" @click="cancel">
                  Cancelar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit">
                  Crear Texto
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-container>
      </v-card-text>
    </v-card>

    <div class="text-center ma-2">
      <v-snackbar :color="color" v-model="snackbar">
        {{ text }}.
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            OK
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-row>
</template>

<script>
import InfoButton from '@/components/UI/InfoButton.vue';

export default {
  components: {
    InfoButton
  },
  props: {
    bottomStyle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      text: '',
      color: '',
      snackbar: false,
      type: ["Texto descriptivo", "Texto expositivo", "Texto científico", "Texto argumentativo", "Texto literario", "Texto publicitario", "Texto instructivo", "Texto histórico", "Texto jurídico", "Texto digital", "Texto periodístico", "Diagnóstico", 'Minijuego'],
      form: {
        title: '',
        publicationYear: null,
        author: '',
        content: [],
        font: null,
        type: '',
        description: '',
        image: null,
      }
    }
  },
  methods: {
    paragraphNumber(e) {
      const newContent = [];
      for (let i = 0; i < e; i++) {
        newContent.push(this.form.content[i] || '');
      }
      this.form.content = newContent;
    },
    setSnackBar(color, text, snack) {
      this.color = color;
      this.text = text;
      this.snackbar = snack;
    },
    resetForm() {
      this.form = {
        title: '',
        publicationYear: null,
        author: '',
        content: [],
        font: null,
        type: '',
        description: '',
        image: null,
      };
    },
    async submit() {
      if (this.form.title === '' ||
        this.form.author === '' || this.form.content.length === 0 ||
        this.form.type === '' || this.form.description === '' || this.form.content.some(val => val.length === 0)) {
        this.setSnackBar('red', 'Debe rellenar todos los campos', true);
        return;
      }

      const formData = new FormData();
      Object.keys(this.form).forEach(key => {
        if (key === 'content') {
          formData.append('content', JSON.stringify(this.form.content));
        } else if (key === 'image' && this.form.image) {
          formData.append('image', this.form.image);
        } else {
          formData.append(key, this.form[key]);
        }
      });

      try {
        await this.$store.dispatch('teacher/createText', formData);
        this.resetForm();
        this.setSnackBar('green', 'Texto creado con éxito', true);
      } catch (error) {
        console.error('Error details:', error.response ? error.response.data : error);
        this.setSnackBar('red', `Error: ${error.message}`, true);
      }
    }
  }
}
</script>

<style scoped>
  .v-card {
    margin: auto;
  }

  .v-card-title {
    font-size: 24px;
  }

  @media (max-width: 600px) {
    .v-card-title {
      font-size: 18px;
    }
  }
</style>