<template>
  <teacher-drawer>
    <v-container fluid>
        <router-view />
    </v-container>
  </teacher-drawer>
</template>

<script>
import TeacherDrawer from "../../components/teacher/TeacherDrawer";
export default {
  components: {TeacherDrawer}
}
</script>

