<template>
    <v-container>
      <v-btn @click="dialog = true" class="white--text" color="deep-purple accent-4">
        Agregar un Diagnóstico a la asignatura
      </v-btn>
  
      <v-dialog width="80%" v-model="dialog" fluid>
            <v-card outlined style="border: 0.2px solid #333;">
              <div class="d-flex justify-space-between align-center primary white--text">
                <v-card-title>Asociar un Diagnóstico</v-card-title>
                <v-btn class="mr-5 text-white " color="red" @click="dialog = false"> X </v-btn>
              </div>
              <v-form @submit.prevent="submit" ref="form" lazy-validation>
                <v-card-text>
                  <v-select
                    v-model="form.poll"
                    :items="formattedPolls"
                    item-text="formattedTitle"
                    item-value="originalPoll"
                    prepend-icon="mdi-format-title"
                    label="Seleccione una Diagnóstico"
                    required
                    return-object
                    :menu-props="{ maxHeight: '200px' }"
                  >
                  </v-select>
                  <v-select
                    v-model="form.text"
                    :items="texts"
                    item-text="title"
                    prepend-icon="mdi-book"
                    label="Seleccione un texto (Opcional)"
                    required
                    return-object
                    :menu-props="{ maxHeight: '200px' }"
                  >
                  </v-select>
                  <date-component @date="form.limit = $event" />
                  <v-textarea
                    prepend-icon="mdi-format-text-variant"
                    v-model="form.description"
                    auto-grow
                    filled
                    color="deep-purple"
                    label="Instrucciones"
                    rows="3"
                  ></v-textarea>
                </v-card-text>
  
                <v-card-actions class="justify-space-between">
                  <v-btn color="error" @click="cancel"> Cancelar </v-btn>
                  <v-btn color="primary" type="submit">
                    Crear Actividad de Diagnóstico
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>

      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import DateComponent from "../../UI/DateComponent";
  import axios from "axios";
  import Swal from "sweetalert2";
  
  export default {
    props: {
      lessonId: {
        type: String,
      },
    },
    components: { DateComponent },
    computed: {
      polls() {
        return this.$store.getters["teacher/getPolls"];
      },
      formattedPolls() {
        return this.polls.map(poll => ({
          formattedTitle: `${poll.id} - ${poll.title}`,
          originalPoll: poll
        }));
      },
      isFormValid() {
        const today = new Date().setHours(0, 0, 0, 0);
        const selectedDate = new Date(this.form.limit).setHours(0, 0, 0, 0);
        return this.form.poll && this.form.description && selectedDate > today;
      },
    },
    data() {
      return {
        selected: [],
        form: {
          lessonId: parseInt(this.$route.params.id),
          poll: null,
          text: null,
          limit: null,
          description: "",
        },
        dialog: false,
        texts: [],
      };
    },
    methods: {
      async getPolls() {
        await this.$store.dispatch("teacher/poll");
      },
      async getTexts() {
        const response = await axios.get('/teacher/text');
        this.texts = response.data.texts;
      },
      async submit() {
        if (!this.isFormValid) {
          Swal.fire({
            title: "Error",
            text: "Por favor, complete todos los campos y asegúrese de que la fecha sea mayor que el día de hoy.",
            icon: "error",
            confirmButtonText: "Aceptar"
          });
          return;
        }
  
        await Swal.fire({
          title: "¿Desea crear el Diagnóstico?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí!",
          cancelButtonText: "Volver",
        }).then(async (result) => {
          if (result.isConfirmed) {
            let data = {
              pollId: this.form.poll.originalPoll.id,
              lessonId: parseInt(this.$route.params.id),
              textId: this.form.text ?  this.form.text.id : null ,
              limit: this.form.limit,
              description: this.form.description,
            };
  
            try {
              const response = await axios.post("teacher/poll_activity", data);
              if (response.data.ok) {
                Swal.fire("Creada", "Diagnóstico creado con éxito", "success");
                this.resetForm();
                this.dialog = false;
                this.$emit('poll-activity-created'); // Emitir evento después de la creación exitosa
              } else {
                console.log(data)
                Swal.fire("Error", "Ocurrió un error al crear el Diagnóstico: " + response.data.error, "error");
              }
            } catch (e) {
              Swal.fire("Error", "Ocurrió un error al crear el Diagnóstico", "error");
              console.log(e);
            }
          }
        });
      },
      cancel() {
        this.resetForm();
        this.dialog = false;
      },
      resetForm() {
        this.selected = [];
        this.form = {
          poll: null,
          text: null,
          limit: null,
          description: "",
        };
      },
    },
    async created() {
      await this.getPolls();
      await this.getTexts();
    },
  };
  </script>