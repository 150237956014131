<template>
  <v-card class="mx-auto d-flex flex-column justify-space-between" max-width="400" height="80%">
    <v-img
      class="white--text align-end"
      height="40%"
      width="100%"
      :src="computedImage"
    >
    </v-img>

    <v-card-title>{{ title }}</v-card-title>

    <v-card-text class="text--primary">
      <div>{{ description }}</div>
    </v-card-text>

    <v-spacer></v-spacer> <!-- Añade un espacio flexible que empuja el contenido de los botones hacia abajo -->

    <v-card-actions>
      <v-col cols="12">
        <v-row class="justify-center align-center mb-4">
          <slot name="activities"></slot>
        </v-row>
        <v-row class="justify-center align-center">
          <slot name="polls"></slot>
        </v-row>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  computed: {
    cardImage() {
      if(this.image === 'question') return 'question.jpg'
      if(this.image === 'text') return 'text.jpg'
      if(this.image === 'lesson') return 'lesson.jpg'
      if(this.image === 'activity') return 'activity.jpg'
      return 'question.jpg';
    },
    computedImage() {
      // Verifica si hay una imagen personalizada, de lo contrario usa la imagen predeterminada basada en el tipo de card
      return this.customImage ? this.customImage : require(`@/assets/images/${this.cardImage}`);
    }
  },
  props: ["title", "description", "image", "customImage"],
  methods: {
  }
}
</script>

<style scoped>
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.justify-space-between {
  justify-content: space-between;
}
</style>