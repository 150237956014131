<template>
  <div class="px-6">
    <v-tabs v-model="tab">
      <v-tab href="#tab-1">Texto</v-tab>
      <v-tab href="#tab-2">Palabras Guardadas</v-tab>
      <v-tab href="#tab-3" v-if="finish">Actividades</v-tab>
      <v-tab disabled>Tiempo {{ timeFormatted }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-card class="mt-4 pa-4" width="100vw">
          <v-card-title v-if="!hideTitle">
            <v-row>
              <v-col cols="4">
                <v-text-field class="text--accent-4 mx-0 py-0" readonly label="Autor" :value="textData.author" />
              </v-col>
              <v-col cols="8">
                <v-text-field class="text--accent-4 mx-0 py-0" readonly label="Título" :value="textData.title" />
              </v-col>
              <v-col cols="8">
                <v-text-field v-if="textData.font && textData.font.length > 0" class="text--accent-4 mx-0 py-0" readonly
                  label="Fuente" :value="textData.font" />
              </v-col>
              <v-col cols="2">
                <v-text-field v-if="
                  textData.publicationYear &&
                  textData.publicationYear.length > 0
                " class="text--accent-4 mx-0 py-0" readonly label="Año de publicación"
                  :value="textData.publicationYear" />
              </v-col>
              <v-col cols="2">
                <v-text-field v-if="textData.type && textData.type.length > 0" class="text--accent-4 mx-0 py-0" readonly
                  label="Tipo de texto" :value="textData.type" />
              </v-col>
              <v-col cols="10">
                <v-text-field v-if="textData.description && textData.description.length > 0"
                  class="text--accent-4 mx-0 py-0" readonly label="Descripción" :value="textData.description" />
              </v-col>
              <v-col cols="2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="hideTitle = true" v-bind="attrs" v-on="on">
                      mdi-eye-off
                    </v-icon>
                  </template>
                  <span>Esconder Info</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-title>

          <v-tooltip bottom v-if="hideTitle">
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="hideTitle = false" v-bind="attrs" v-on="on">
                mdi-eye
              </v-icon>
            </template>
            <span>Mostrar Info</span>
          </v-tooltip>

          <v-card-title v-if="!finish" style="background-color: aliceblue">
            <v-row class="mt-3" align="center" justify="space-around">
              <v-col xl="1" lg="2" sm="6" md="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="changeTextSize('minus')" block color="primary" dark v-bind="attrs" v-on="on">
                      Aa-
                    </v-btn>
                  </template>
                  <span>Disminuir Tamaño Fuente</span>
                </v-tooltip>
              </v-col>
              <v-col xl="1" lg="2" sm="6" md="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="changeTextSize('initial')" block color="primary" dark v-bind="attrs" v-on="on">
                      Aa
                    </v-btn>
                  </template>
                  <span>Fuente Normal</span>
                </v-tooltip>
              </v-col>
              <v-col xl="1" lg="2" sm="6" md="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="changeTextSize('plus')" block color="primary" dark v-bind="attrs" v-on="on">
                      Aa+
                    </v-btn>
                  </template>
                  <span>Aumentar Tamaño Fuente</span>
                </v-tooltip>
              </v-col>

              <v-col xl="1" lg="2" sm="6" md="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="raeSearch" block color="primary" dark v-bind="attrs" v-on="on">
                      <v-icon dark left> mdi-book-alphabet </v-icon>
                      Buscar
                    </v-btn>
                  </template>
                  <span>Buscar Palabra Diccionario</span>
                </v-tooltip>
              </v-col>

              <v-col xl="1" lg="2" sm="6" md="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="setHighLight" block color="primary" dark v-bind="attrs" v-on="on">
                      <v-icon dark left> mdi-content-save </v-icon>
                      Guardar
                    </v-btn>
                  </template>
                  <span>Guardar Palabra</span>
                </v-tooltip>
              </v-col>

              <v-col xl="1" lg="2" sm="6" md="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="highLightWord" block color="primary" dark v-bind="attrs" v-on="on">
                      Resaltar
                    </v-btn>
                  </template>
                  <span>Resaltar Palabra/s</span>
                </v-tooltip>
              </v-col>
              
              <v-col xl="1" lg="2" sm="6" md="3">
                <v-btn block color="success" @click="finishRead">
                  Terminar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text style="background-color: aliceblue">
            <v-container fluid ref="target" v-if="this.activity.activity.minigame === null">
              <v-row class="mt-2 mx-2 text--primary" v-for="(data, i) in textParagraphs" :key="i">
                <v-col cols="7" v-if="data.subTitle">
                  <p id="test" class="text-justify" :style="{ fontSize: textSize }" v-html="data.subTitle"></p>
                </v-col>
                <v-col cols="11">
                  <div class="text-justify" :style="{ fontSize: textSize }" v-html="data.description"></div>

                  <v-text-field v-if="paragraphQuestion[i] && paragraphQuestion[i].visible" :disabled="finish"
                    @click:prepend="paragraphQuestion[i].visible = false" v-model="paragraphQuestion[i].answer"
                    @input="saveState(6)"
                    :prepend-icon="
                      paragraphQuestion[i].answer === ''
                        ? 'mdi-close'
                        : 'mdi-check-all'
                    " :label="`Pregunta Párrafo ${i + 1}`" />
                </v-col>
                <v-col cols="1" v-if="textData.type !== 'Diagnóstico'">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon @click="
                        paragraphQuestion[i].visible =
                        !paragraphQuestion[i].visible
                      " :color="
                          paragraphQuestion[i] &&
                            paragraphQuestion[i].answer.length > 0
                            ? 'success'
                            : 'orange'
                        " v-bind="attrs" v-on="on">
                        mdi-comment-question
                      </v-icon>
                    </template>
                    <span>{{
                        paragraphQuestion[i] &&
                          paragraphQuestion[i].answer.length > 0
                          ? "Pregunta Realizada."
                          : "Realizar Pregunta."
                    }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
            <v-container v-else ref="target" >

                <v-col v-if="this.activity.activity.minigame === 'image_minigame'" >
                  <h1 class="text-justify mt-8 mb-4"><strong>Instrucciones: seleccione la imagen que considere que sea asociada a el parrafo</strong></h1>
                

                  <v-row width="100%" class="text--primary mt-3" v-for="(element, i) in textParagraphs" :key="i" >
                      <v-col  cols="7" >
                        <div class="text-justify" :style="{ fontSize: textSize }" v-html="element.description"></div>
                        <v-text-field v-if="paragraphQuestion[i] && paragraphQuestion[i].visible" :disabled="finish"
                          @click:prepend="paragraphQuestion[i].visible = false" v-model="paragraphQuestion[i].answer"
                          @input="saveState(7)"
                          :prepend-icon="
                            paragraphQuestion[i].answer === ''
                              ? 'mdi-close'
                              : 'mdi-check-all'
                          " :label="`Pregunta Párrafo ${i + 1}`" />
                      </v-col>
                      <v-col cols="1" v-if="textData.type !== 'Diagnóstico'">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon @click="
                              paragraphQuestion[i].visible =
                              !paragraphQuestion[i].visible
                            " :color="
                                paragraphQuestion[i] &&
                                  paragraphQuestion[i].answer.length > 0
                                  ? 'success'
                                  : 'orange'
                              " v-bind="attrs" v-on="on">
                              mdi-comment-question
                            </v-icon>
                          </template>
                          <span>{{
                              paragraphQuestion[i] &&
                                paragraphQuestion[i].answer.length > 0
                                ? "Pregunta Realizada."
                                : "Realizar Pregunta."
                          }}</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="4" >
                        <v-row>
                          <v-carousel class="pa-3" v-model="selectedImages[element.id]" hide-delimiters height="360px" @change="onImageSelect(element.id, $event)">
                            <v-carousel-item  class="image-container" v-for="(image, index) in images" :key="index" :value="image">
                              <v-img :src="image" class="contained-image"></v-img>
                            </v-carousel-item>
                            <v-carousel-item :value="null">
                              <v-card class="contained-image d-flex justify-center align-center">
                                <h2>Ninguna imagen</h2>
                              </v-card>
                            </v-carousel-item>
                          </v-carousel>
                        </v-row>
                      </v-col>

                  </v-row>  
                </v-col>

                <div   v-if="this.activity.activity.minigame === 'unordered_text'">
                  <h1 class="text-justify mb-8"><strong>Instrucciones: Ordene los párrafos a continuación de manera que el texto sea coherente y tenga sentido, puede mover los parrafos con este boton </strong>
                    "<img :src="arrowIcon" alt="Arrow Icon" class="svg-icon" />"
                  </h1>

                  <v-row  class="mt-4 d-flex justify-center align-center text--primary" v-if="!finish">
                  
                  <draggable 
                    :list="textParagraphs" 
                    :disabled="finish" 
                    class="list-group" 
                    ghost-class="ghost" 
                    handle=".drag-handle" 
                    @start="dragging = true" 
                    @end="dragging = false" 
                    @change="onParagraphOrderChange"
                  >
                  <v-col cols="12" class="list-group-item" v-for="(element, i) in textParagraphs" :key="i">
                    <v-row >
                      <v-col cols="9">
                        <p  class="text-justify" :style="{ fontSize: textSize }" v-html="element.description">
                          </p>
                        </v-col>
                        <v-col cols="1">
                          <!-- Manija de arrastre -->
                          <img :src="arrowIcon" alt="Arrow Icon" class=" drag-handle svg-icon" />

                        </v-col>
                        <v-col cols="1">
                          <div v-if="textData.type !== 'Diagnóstico'" :style="{ fontSize: textSize }">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                  @click="toggleQuestionVisibility(i)" 
                                  :color="paragraphQuestion[i] && paragraphQuestion[i].answer.length > 0 ? 'success' : 'orange'" 
                                  v-bind="attrs" v-on="on"
                                >
                                  mdi-comment-question
                                </v-icon>
                              </template>
                              <span>{{ paragraphQuestion[i] && paragraphQuestion[i].answer.length > 0 ? "Pregunta Realizada." : "Realizar Pregunta." }}</span>
                            </v-tooltip>
                          </div>
                      
                        </v-col>
                      </v-row>
                      <v-text-field 
                          v-if="paragraphQuestion[i] && paragraphQuestion[i].visible" 
                          :disabled="finish" 
                          @click:prepend="paragraphQuestion[i].visible = false" 
                          :style="{ fontSize: textSize }" 
                          v-model="paragraphQuestion[i].answer" 
                          @input="saveState(10)"
                          :prepend-icon="paragraphQuestion[i].answer === '' ? 'mdi-close' : 'mdi-check-all'" 
                          :label="`Pregunta Párrafo ${i + 1}`" 
                        />
  
                    
                    </v-col>
                  </draggable>
                </v-row>

                <v-row class="text--primary" v-for="(element, i) in textParagraphs" :key="i" v-else>
                  <v-col cols="6" class="list-group-item">
                    <p class="text-justify"
                      :style="{ fontSize: textSize, color: correctOrder[i] === element.id ? 'green' : 'red' }"
                      v-html="element.description">
                    </p>
                  </v-col>

                  <v-col cols="6" class="list-group-item">
                    <p class="text-justify" :style="{ fontSize: textSize }"
                      v-html="textParagraphs.find(el => el.id === correctOrder[i]).description">
                    </p>
                    <div class="text-justify" v-if="textData.type !== 'Diagnóstico'" :style="{ fontSize: textSize}">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon @click="
                            paragraphQuestion[i].visible =
                            !paragraphQuestion[i].visible
                          " :color="
                              paragraphQuestion[i] &&
                                paragraphQuestion[i].answer.length > 0
                                ? 'success'
                                : 'orange'
                            " v-bind="attrs" v-on="on">
                            mdi-comment-question
                          </v-icon>
                        </template>
                        <span>{{
                            paragraphQuestion[i] &&
                              paragraphQuestion[i].answer.length > 0
                              ? "Pregunta Realizada."
                              : "Realizar Pregunta."
                        }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>

                </v-row>
                </div>
             
            </v-container>
          </v-card-text>

          <v-snackbar :color="color" v-model="snackbar">
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn v-bind="attrs" @click="snackbar = false"> OK </v-btn>
            </template>
          </v-snackbar>
        </v-card>
        <ImageModal
          :visible="dialog"
          :imageUrl="selectedImage"
          @close="dialog = false"
        />
      </v-tab-item>

      <v-tab-item value="tab-2">
        <words-table :words="savedWords" />
      </v-tab-item>

      <v-tab-item value="tab-3">
        <student-activity  :id="Number(this.activityId)" :minigame="selectedImages" :activity="activity" :order="this.activity.activity.minigame === 'unordered_text' ? textParagraphs.map(el => el.id) : []"
          :forceFinish="forceFinish" :time="time" :words="savedWords" :paragraph-questions="paragraphQuestion"
          :highLights="highLights" />
      </v-tab-item>
    </v-tabs-items>
  
  </div>
</template>

<script>
import axios from "axios";
import WordsTable from "../../../../components/students/WordsTable";
import StudentActivity from "../../../../components/students/StudentActivity";
import Swal from "sweetalert2";
import draggable from 'vuedraggable';
import ImageModal from '../../../../components/UI/ImageModal.vue';
import arrowIcon from '@/assets/icons/arrow-icon.svg';

export default {
  components: { StudentActivity, WordsTable, draggable, ImageModal },
  watch: {
    tab() {
      if (this.tab != "tab-1") {
        this.saveState(11);
      }
    }
  },
  data() {
    return {
      correctOrder: [],
      dragging: false,
      forceFinish: false,
      interval: null,
      color: "red",
      text: "",
      snackbar: false,
      hideTitle: false,
      savedWords: [],
      finish: false,
      tab: "tab-1",
      textData: [],
      textParagraphs: [],
      initial: 17,
      auxInitial: 17,
      textSize: "17px",
      highLights: [],
      canHighLight: false,
      time: 0,
      activityId: null,
      paragraphQuestion: [],
      loading: null,
      questionsFromText: [],
      finishText:true,
      activity:[],
      selectedImages: {}, // Para almacenar la imagen seleccionada por cada párrafo
      images: [], // Para almacenar las imágenes disponibles para seleccionar
      imagesWords: {}, // Para almacenar las palabras e imágenes asociadas
      dialog: false,
      selectedImage: '',
      arrowIcon,
    };
  },
  computed: {
    timeFormatted() {
      const minutes = Math.floor(this.time / 60);
      const seconds = (this.time % 60).toFixed(2);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
  },
  methods: {
    toggleQuestionVisibility(index) {
      this.paragraphQuestion[index].visible = !this.paragraphQuestion[index].visible;
      this.saveState(12);
    },
    onParagraphOrderChange(event) {
      // Actualiza el orden de paragraphQuestion para mantenerlo sincronizado con textParagraphs
      const movedQuestion = this.paragraphQuestion[event.moved.oldIndex];
      
      // Si el elemento fue movido hacia arriba
      if (event.moved.newIndex < event.moved.oldIndex) {
        this.paragraphQuestion.splice(event.moved.oldIndex, 1);
        this.paragraphQuestion.splice(event.moved.newIndex, 0, movedQuestion);
      }
      // Si el elemento fue movido hacia abajo
      else {
        this.paragraphQuestion.splice(event.moved.oldIndex, 1);
        this.paragraphQuestion.splice(event.moved.newIndex, 0, movedQuestion);
      }
      this.saveState(13);
    },  
    onImageSelect(paragraphId, imageUrl) {
      this.$set(this.selectedImages, paragraphId, imageUrl);
      console.log("jdsnfjsdbnfjh")
      if (paragraphId !== null && imageUrl !== null) {
        this.saveState(14); // Guardar estado cuando se selecciona una imagen
      }
    },
    async finishRead() {
      this.finishText=false;
      await Swal.fire({
        title: "¿Desea finalizar la lectura?",
        text: "Pasará a responder preguntas",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí!",
        cancelButtonText: "Volver",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Swal.fire(
            "Completada!",
            "La lectura ha sido completada..",
            "success"
          );
          this.finish = true;
          this.tab = "tab-3";
          this.startTimer();
          this.saveState(15);
        } else {
          this.finishText=true;
          // Si el usuario cancela, reanuda el intervalo
          this.saveState(16);
          this.startTimer();
        }
      });
    },
    setHighLight() {
      // if (highlight === "") {
      //   this.snackbar = true;
      //   this.color = "red";
      //   this.text = "Debe seleccionar una palabra.";
      // }
      this.canHighLight = true;
      this.saveWord();
    },
    changeTextSize(statement) {
      if (statement === "plus") {
        this.initial++;
        this.textSize = `${this.initial}px`;
      }

      if (statement === "minus") {
        if (this.initial > 1) {
          this.initial--;
          this.textSize = `${this.initial}px`;
        }
      }

      if (statement === "initial") {
        this.initial = this.auxInitial;
        this.textSize = `${this.initial}px`;
      }
      this.saveState(17);
    },
    saveState( number) {
      console.log(number)
      if (!this.activityId) {
        console.error("Activity ID is not defined");
        return;
      }

      const state = {
        paragraphQuestion: this.paragraphQuestion,
        selectedImages: this.selectedImages,
        textSize: this.textSize,
        highLights: this.highLights,
        finish: this.finish,
        finishText: this.finishText,
        tab: this.tab
      };
      localStorage.setItem('activity_state_' + this.activityId, JSON.stringify(state));
      console.log("State saved:", state);
    },

    loadState() {
      if (!this.activityId) {
        console.error("Activity ID is not defined");
        return;
      }
      const state = localStorage.getItem('activity_state_' + this.activityId);
      if (state) {
        const parsedState = JSON.parse(state);
        this.paragraphQuestion = parsedState.paragraphQuestion || [];
        this.selectedImages = parsedState.selectedImages || {};
        this.textSize = parsedState.textSize || '17px';
        this.highLights = parsedState.highLights || [];
        this.finish = parsedState.finish || false;
        this.finishText = parsedState.finishText || true;
        this.tab = parsedState.tab || null;
        console.log("State loaded:", parsedState);

      } else {
        console.warn("No state found for activity ID:", this.activityId);
      }
    },
    async getText() {
      try {
        const response = await axios.get(
          "student/activity/text/" + this.activityId
        );
        const { text, paragraph } = response.data;
        this.textData = text;
        this.textParagraphs = paragraph;
        this.imagesWords = JSON.parse(text.images_words); // Almacenar el JSON images_words

        // Subrayar las palabras en el texto y agregar enlaces a las imágenes
        this.textParagraphs = this.textParagraphs.map(paragraph => {
            for (const word in this.imagesWords) {
              const escapedWord = this.escapeRegExp(word);
              const regex = new RegExp(`${escapedWord}`, 'g');
              const linkTag = `<a class="image-link" data-image="${this.imagesWords[word]}" style="color: blue; text-decoration: underline;">${word}</a>`;
              paragraph.description = paragraph.description.replace(regex, linkTag);
            }
            return paragraph;
          });

      } catch (e) {
        console.log(e);
      }
    },
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& significa toda la coincidencia
    },
    searchWord(word) {
      return this.savedWords.find((data) => data === word);
    },
    showImageModal(imageUrl) {
      console.log(imageUrl)
      this.selectedImage = imageUrl;
      this.dialog = true;
    },
    highLightWord() {
      let word = window.getSelection().toString().trim();
      if (!word) {
        this.showSnackbar('Debe seleccionar una o varias palabras.', 'red');
        return;
      }

      const escapedWord = this.escapeRegExp(word);
      const regex = new RegExp(`\\b${escapedWord}\\b`, 'g');

      let wordIndex = this.highLights.indexOf(word);

      this.textParagraphs.forEach(paragraph => {
        if (wordIndex !== -1) {
          paragraph.description = paragraph.description.replaceAll(
            `<strong style="background-color: yellow">${escapedWord}</strong>`,
            word
          );
        } else {
          paragraph.description = paragraph.description.replaceAll(
            regex,
            `<strong style="background-color: yellow">${word}</strong>`
          );
        }
      });
    

      if (wordIndex !== -1) {
        this.highLights.splice(wordIndex, 1);
        this.saveState(1);
        this.showSnackbar('Palabra desresaltada con éxito.', 'green');
      } else {
        this.highLights.push(word);
        this.saveState(2);
        this.showSnackbar('Palabra resaltada con éxito.', 'green');
      }

    },

    raeSearch() {
      let word = window.getSelection().toString().trim();
      if (word.length === 0) {
        this.snackbar = true;
        this.color = "red";
        this.text = "Debe seleccionar una palabra.";
        return;
      }
      window.open(
        `https://dle.rae.es/${word}?m=form`,
        "_blank",
        "width=600,height=600"
      );
    },
    testSPan() {
      console.log('span');
    },
    saveWord() {
      let highlight = window.getSelection().toString().trim();
      if (highlight.trim() !== "") {
        let count = 0;

        const words = highlight.split(" ");

        words.find((data) => {
          if (data === "") count++;
        });

        if (this.canHighLight && words.length - count === 1) {
          if (this.searchWord(highlight)) {
            this.canHighLight = false;
            this.snackbar = true;
            this.color = "orange";
            this.text = "Palabra: " + highlight + " ya está guardada";
            this.saveState(3);
            return;
          }

          this.savedWords.push(highlight);
          this.snackbar = true;
          this.text = "Palabra guardada con éxito.";
          this.color = "green";
          this.saveState(4);
          this.canHighLight = false;
        }
      }
      this.canHighLight = false;
    },
    showSnackbar(message, color) {
      this.snackbar = true;
      this.color = color;
      this.text = message;
    },
    startTimer() {
      console.log('start timer')

       // Detener cualquier intervalo previo si existe
        if (this.interval) {
          clearInterval(this.interval);
        }
        
      if (localStorage.getItem('activity_time_' + this.activityId)) {
        
        let timeData = JSON.parse(localStorage.getItem('activity_time_' + this.activityId));
        let limitTime = timeData.limitTime;
        let timePased = timeData.timePased;
        this.time = timePased;

        if (limitTime && timePased >= limitTime) {
          this.finish = true;
          this.tab = "tab-3";
          clearInterval(this.interval);
          this.forceFinish = true;
        }
      }

      this.interval = setInterval(async () => {
        
        if (localStorage.getItem('activity_time_' + this.activityId)) {
          let timeData = JSON.parse(localStorage.getItem('activity_time_' + this.activityId));
          let limitTime = timeData.limitTime;
          let timePased = timeData.timePased;
          console.log('timePased', timePased)
          console.log('limitTime', limitTime)
          this.time = timePased;
          const data = { limitTime: limitTime, timePased: timePased };
          if (this.finish || this.finishText) {
            this.time++;
          }
          data.timePased = this.time;
          localStorage.setItem('activity_time_' + this.activityId, JSON.stringify(data));

          if (data.limitTime && data.timePased >= data.limitTime) {
            console.log(data.timePased, data.limitTime)
            this.finish = true;
            this.tab = "tab-3";
            clearInterval(this.interval);
            this.forceFinish = true;
          }
        } else {
          const data = { limitTime: Number(this.activity.activity.time) * 60 , timePased: this.time };
          localStorage.setItem('activity_time_' + this.activityId, JSON.stringify(data));
          if (this.finish || this.finishText) {
            this.time++;
          }
        }
      }, 1000);
    }
  },
  async created() {
    this.activityId = this.$route.params.id;

    // Obtener la actividad antes de cargar los textos
    const aux = await axios.get("student/activity/" + this.activityId);
    this.activity = aux.data;

    // Obtener el texto y párrafos asociados
    await this.getText();

    // Cargar el estado guardado si existe
    this.loadState();

    // Procesar los párrafos
    this.textParagraphs.forEach(paragraph => {
      // Solo agregar a correctOrder si no está presente
      if (!this.correctOrder.includes(paragraph.id)) {
        this.correctOrder.push(paragraph.id);

        // Solo agregar a paragraphQuestion si no está presente
        if (!this.paragraphQuestion.find(q => q.id === paragraph.id)) {
          this.paragraphQuestion.push({
            id: paragraph.id,
            answer: "",
            textId: this.textData.id,
            visible: false,
          });
        }

      }

      // Agregar imagen si no está ya en la lista
      if (paragraph.image && !this.images.includes(paragraph.image)) {
        this.images.push(paragraph.image);
      }

      // Asegurar que selectedImages tenga una entrada para el párrafo
      if (!Object.prototype.hasOwnProperty.call(this.selectedImages, paragraph.id)) {
        this.$set(this.selectedImages, paragraph.id, null);
      }
    });

    if (this.activity.activity.minigame === 'unordered_text') {
      this.textParagraphs.sort(() => 0.5 - Math.random());
    }

    // Event Listener para guardar palabras e imágenes asociadas
    document.addEventListener("click", (event) => {
      const link = event.target.closest('.image-link');
      if (link) {
        event.preventDefault();
        const imageUrl = link.dataset.image;
        this.showImageModal(imageUrl);
      }
      if (event.target === this.$refs.target || event.target.contains(this.$refs.target)) {
        this.saveWord();
      }
    });

    // Iniciar el temporizador
    this.startTimer();
    this.saveState(5);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
};
</script>
<style scoped>
.image-container {
  width: 100%;
  height: 350px; /* Set a fixed height for the image container */
  display: flex;
  padding: 7px;
  align-items: center;
  justify-content: center;
}

.contained-image {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}

.svg-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  fill: currentColor; /* Usa el color actual del texto */
}
</style>
