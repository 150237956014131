<template>
  <v-row>
    <v-col cols="12" sm="6" md="4">
      <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="date" label="Fecha Límite" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-date-picker @change="changeDate" v-model="date" scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.dialog.save(date)">OK</v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      default: () => new Date().toISOString().substr(0, 10),
    },
  },
  data() {
    return {
      menu: false,
      modal: false,
      menu2: false,
    };
  },
  methods: {
    async changeDate() {
      await this.$emit('date', this.date);
    }
  },
}
</script>