<template>
    <v-card  >
      <v-form @submit.prevent="submit" ref="form" lazy-validation>
        <v-card-title class="primary white--text">
          Añadir Profesor
        </v-card-title>
        <v-card-text>
          <v-row>
          <v-col>
            <v-text-field
              :rules="[rules.required]"
              v-model="form.name"
              prepend-icon="mdi-format-title"
              label="Nombre"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :rules="[rules.required]"
              v-model="form.lastName"
              prepend-icon="mdi-format-title"
              label="Apellido Paterno"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              :rules="[rules.required]"
              v-model="form.secondLastName"
              prepend-icon="mdi-format-title"
              label="Apellido Materno"
            ></v-text-field>
          </v-col>
          <v-col class="d-flex align-center justify-center">
            <v-checkbox
              class="w-12"
              v-model="form.admin"
              label="Ser Administrador"
              value="1"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              :rules="emailRules"
              v-model="form.email"
              prepend-icon="mdi-format-title"
              label="Email"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              type="password"
              :rules="[rules.required]"
              v-model="form.password"
              prepend-icon="mdi-format-title"
              label="Contraseña (Mínimo 8 caracteres)"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              type="password"
              :rules="[rulesPassword.min]"
              hint="Mínimo 8 caracteres"
              v-model="password"
              prepend-icon="mdi-format-title"
              label="Repetir Contraseña"
            ></v-text-field>
          </v-col>
        </v-row>
          
          
          
        </v-card-text>

        <v-card-actions class="mx-3">
          <v-btn color="error" @click="cancel">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" type="submit">Añadir Profesor</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: {
    bottomStyle: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      password: "",
      form: {
        name: "",
        lastName: "",
        secondLastName: "",
        email: "",
        password: "",
        rol: 1,
        admin:0
      },
      emailRules: [
        (v) => !!v || "Campo Requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail debe ser válido",
      ],
      rules: {
        required: (value) => !!value || "Campo Requerido",
      },
      rulesPassword: {
        required: (value) => !!value || "Campo Requerido",
        min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
      },
    };
  },

  methods: {
    resetForm() {
      this.form = {
        name: "",
        lastName: "",
        secondLastName: "",
        email: "",
        password: "",
        rol: 1,
        admin: 0
      };
      this.password = "";
    },

    async submit() {
      if (
        this.form.name === "" ||
        this.form.lastName === "" ||
        this.form.secondLastName === "" ||
        this.form.email === null ||
        this.form.password.length < 8 ||
        this.password.length < 8
      ) {
        return Swal.fire("", "Todos los campos son requeridos", "error");
      }

      if (this.form.password !== this.password) {
        return Swal.fire("", "Las contraseñas no coinciden", "error");
      }

      await axios
        .post("teacher/createTeacher", this.form)
        .then(() => {
          this.resetForm();
          return Swal.fire("", "Profesor creado con éxito!", "success");
        })
        .catch(() => {
          return Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error.",
          });
        });
    },

    cancel() {
      this.resetForm();
    },
  },
};
</script>