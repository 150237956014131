<template>
    <v-dialog v-model="dialog" persistent max-width="80%">
      <template v-slot:activator="{ on, attrs }" >
        <v-btn class="my-4" color="primary" outlined small v-bind="attrs" v-on="on" @click="openDialog">
          <v-icon dark>
              mdi-pencil
          </v-icon>
        </v-btn>
      </template>
    
      <v-container fluid>
        <v-card>
          <v-card-title class="primary white--text">Editar Actividad</v-card-title>
          <v-form @submit.prevent="submit" ref="form" lazy-validation>
            <v-card-text>
              <date-component :date="form.date" @date="form.date = $event" />
              <v-textarea class="mt-5" prepend-icon="mdi-format-text-variant" v-model="form.description" auto-grow filled
                color="deep-purple" label="Descripción" rows="3"></v-textarea>
              <v-text-field type="number" v-model="form.limitTime" prepend-icon="mdi-counter"
                label="Tiempo límite minutos "></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn color="error" @click="cancel"> Cancelar </v-btn>
              <v-spacer></v-spacer>
              <v-btn  color="primary" type="submit">
                  Guardar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-container>
    </v-dialog>
  </template>
  
  <script>
import DateComponent from "../../UI/DateComponent";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: {
    activity: {
      type: Object,
    },
  },
  components: { DateComponent },
  computed: {
    isFormValid() {
      const isDateValid = new Date(this.form.date) >= new Date(this.activity.date);
      return isDateValid && this.form.description.trim() !== "";
    },
  },
  data() {
    return {
      dialog: false,
      form: {
        date: this.activity.date ? this.formatDate(this.activity.date) : null,
        description: this.activity.description || "",
        limitTime: this.activity.limitTime || null,
      },
    };
  },
  methods: {
    async submit() {
      
      if (!this.isFormValid) {
        Swal.fire('Error', 'La nueva fecha debe ser mayor a la fecha actual de la actividad y la descripción no puede estar vacía.', 'error');
        return;
      }

      await Swal.fire({
        title: "¿Desea editar la actividad?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí!",
        cancelButtonText: "Volver",
      }).then(async (result) => {
        if (result.isConfirmed) {

          let data = {
            id: this.activity.id,
            date: this.form.date,
            description: this.form.description,
            limitTime: this.form.limitTime ? parseInt(this.form.limitTime) : null,
          };

          const response = await axios.put("teacher/activity/updateActivity", data);

          // Verificar si la solicitud PUT fue exitosa
          if (response.status === 200) {
            // Realizar acciones adicionales si la solicitud fue exitosa, como restablecer el formulario
              this.$emit('activity-updated');
               await Swal.fire("Editada", "Actividad editada con éxito", "success");
             this.dialog = false;

          } else {
            // Manejar el caso de error
            console.error("Error al actualizar la actividad:", response.data.error);
            await Swal.fire("Error", "Error al editar la actividad", "error");
          }
        }
      });
    },
    formatDate(date) {
      const formattedDate = new Date(date);
      // Realizar aquí cualquier operación necesaria para darle el formato deseado
      return formattedDate.toISOString().substr(0, 10); // Formato YYYY-MM-DD
    },
    cancel() {
      this.resetForm();
      this.dialog = false;
    },
    resetForm() {
      this.form = {
        date: this.formatDate(this.activity.date),
        description:  this.activity.description,
        limitTime: this.activity.limitTime
      };
    },
    openDialog() {
      // Método para abrir el diálogo
      this.dialog = true;
    },
  },
  created() {
    // Inicializar el formulario con los datos de la actividad
    if (this.activity) {
      this.form.date = this.formatDate(this.activity.date);
      this.form.description = this.activity.description;
      this.form.limitTime = this.activity.limitTime;
    }
  },
};
</script>