<script>
import {Bar} from "vue-chartjs";
import axios from "axios";
export default {
  data() {
    return {
      labelName: [],
      dataAnswer: [],
      dataColor: [],
      answersData: [],
      replies: [],
    }
  },
  props: ['data', 'activityId'],
  extends: Bar,
  async mounted() {

    await axios.get('studentReplies/' + this.activityId).then(response => {
      const { data } = response;
      data.reply.forEach( reply => this.replies.push(reply))
    })

    await this.data.answer.forEach( (dataAnswer, index) => {
      this.answersData.push({ id: dataAnswer.id, data: 0 })
      this.dataColor.push(dataAnswer.isCorrect ? 'green' : 'red')
      this.labelName.push(`${dataAnswer.description}`)
      this.dataAnswer.push(0);
      this.replies.forEach(data => {
        let info = JSON.parse(data.answer);
        info.forEach(dataReply => {
          if(dataReply.type === 2) {
            dataReply.answer.forEach( dataReply2 => {
              if(dataReply2.id === dataAnswer.id) {
                this.dataAnswer[index]+=1;
              }
            })
          }
          else {
            if(dataReply.answer.id === dataAnswer.id) {
              this.dataAnswer[index]+=1;
            }
          }

        })
      })
    })


    this.renderChart(
        {
          labels: this.labelName,
          datasets: [{
            axis: 'y',
            label: this.data.question.title,
            data: this.dataAnswer,
            backgroundColor: this.dataColor,
            borderWidth: 1
          }]

        },
        { responsive: true, maintainAspectRatio: false,
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }, }
    );
  }
};
</script>