<template>
  <div>
    <v-tabs v-model="activeTab">
      <v-tab href="#tab-1">Textos</v-tab>
      <v-tab href="#tab-2">Crear textos</v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab">
      <v-tab-item value="tab-1">
        <v-container class="mt-5 pa-6" fluid>
          <v-row class="image-container" v-if="!loading && this.textsData.length === 0">
            <v-img class="contained-image" width="70%" :src="require('@/assets/images/nodata.jpg')"></v-img>
          </v-row>
          <v-row class="d-flex justify-center" v-if="!loading && this.textsData.length === 0">
            <v-btn class="text-center" color="primary" @click="activeTab='tab-2'">Actualmente no posee Textos Creados, haga click para crear uno</v-btn>
          </v-row>
          <v-row v-if="!loading && this.textsData.length > 0">
            <v-autocomplete
              @change="textDetailsBar"
              :items="textsData"
              item-text="title"
              outlined
              dense
              label="Buscar Texto"
              return-object
            ></v-autocomplete>
          </v-row>
          <v-row v-if="!loading && this.textsData.length > 0">
            <v-col v-for="(text, index) in showableArray" :key="index">
              <card-component
                :title="text.title[0].toUpperCase() + text.title.slice(1)"
                :description="text.description[0].toUpperCase() + text.description.slice(1)"
                :image="text.image ? 'text' : 'default'" 
                :customImage="isValidImage(text.image) ? text.image : null"
              >
                <template v-slot:polls>
                  <question-form :text-id="parseInt(text.id)" />
                </template>
                <template v-slot:activities>
                  <v-btn @click="textDetails(text.id)" color="success">Ver Detalles</v-btn>
                </template>
              </card-component>
            </v-col>
          </v-row>
          <v-row v-if="loading" justify="center">
            <v-col class="d-flex justify-center align-center">
              <v-progress-circular size="200" :width="3" color="primary" indeterminate></v-progress-circular>
            </v-col>
          </v-row>
          <v-container fluid class="d-flex justify-center align-center" v-if="!loading && textsData.length > 0">
            <v-btn
              v-for="(index, i) in paginate"
              :key="i"
              @click="current_page = index - 1"
              elevation="3"
              color="primary"
              fab
              style="margin-right: 10px"
            >
              {{ index }}
            </v-btn>
          </v-container>
        </v-container>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <text-form></text-form>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import CardComponent from "../../../components/UI/CardComponent";
import TextForm from "../../../components/teacher/forms/TextForm";
import QuestionForm from "../../../components/teacher/forms/QuestionForm";

export default {
  components: {
    CardComponent,
    TextForm,
    QuestionForm,
  },
  computed: {
    textsData() {
      return this.$store.getters['teacher/getTexts'];
    },
    paginate() {
      return Math.ceil(this.textsData.length / this.items_per_page);
    },
    showableArray() {
      return this.textsData.slice(
        this.current_page * this.items_per_page,
        this.items_per_page + this.current_page * this.items_per_page
      );
    },
  },
  data() {
    return {
      loading: null,
      items_per_page: 6,
      current_page: 0,
      activeTab: "tab-1", // Set the default active tab
    };
  },
  methods: {
    textDetailsBar(text) {
      this.$router.push('/profe/textos/' + text.id);
    },
    textDetails(id) {
      this.$router.push('/profe/textos/' + id);
    },
    isValidImage(image) {
      if (!image || typeof image !== 'string') {
        return false;
      }

      const validExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
      const imageExtension = image.split('.').pop().toLowerCase();

      return validExtensions.includes(imageExtension);
    },
    async getTexts() {
      await this.$store.dispatch('teacher/texts');
    },
  },
  async created() {
    this.loading = true;
    await this.getTexts();
    this.loading = false;
  },
};
</script>

<style scoped>
.image-container {
  width: 100%;
  height: 500px; /* Set a fixed height for the image container */
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
}
.contained-image {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}
</style>