<template>
  <v-container fluid>
    <div v-if="!loading && lessonsData.length === 0">
      <div class="image-container">
        <v-img class="contained-image"  :src="require('@/assets/images/nodata.jpg')"></v-img>
      </div>
      <p class="text-center">Actualmente no posee asignaturas creadas</p>
      <lesson-form class="mt-2" />
    </div> 

    <v-row fluid class="mt-8 justify-center" height="400px">
      <v-card width="70%" class="p-3 m-5 mr-5" v-if="!loading && lessonsData.length > 0" outlined style="border: 0.2px solid #333;">
        <v-card-title >
          <v-toolbar flat>
            <v-toolbar-title>Lista de Asignaturas</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar asignatura" single-line hide-details>
            </v-text-field>

          </v-toolbar>
        </v-card-title>
        <v-data-table :search="search" :headers="headers" :items="lessonsData" item-key="id" class="elevation-1 v-size-full v-fill-height" height="350px">
          <template v-slot:item.actions="{ item }">
            <v-icon color="primary" class="mr-2" @click="toDetails(item)">
              mdi-magnify
            </v-icon>
          </template>
        </v-data-table>
      </v-card>

      <v-card center width="20%" v-if="!loading && lessonsData.length > 0" outlined style="border: 0.2px solid #333;">
        <v-card-actions >
          <v-col class="pt-13">
            <v-row class="mb-3 d-flex jutify-center align-center">
              <lesson-form :bottom-style="true" class=" my-3 "/>
            </v-row>
            <v-row class="mt-3 d-flex jutify-center align-center">
              <DeleteLesson  :lessons="lessonsData"></DeleteLesson>
            </v-row>
             
          </v-col>
          </v-card-actions>
      </v-card>

    </v-row>
    

    <v-row  justify="center">
          <v-col class="d-flex justify-center align-center">
            <v-progress-circular
                v-if="loading"
                size="200"
                :width="3"
                color="primary"
                indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>

  </v-container>
</template>

<script>

import LessonForm from "../../../components/teacher/forms/LessonForm";
import DeleteLesson from './details/DeleteLesson.vue';

export default {

  data() {
    return {
      search: '',
      headers: [],
      loading: null,
      items_per_page: 6,
      current_page: 0,
      showModal: false,
    }
  },

  components: { LessonForm, DeleteLesson },
  computed: {
    lessonsData() {
      return this.$store.getters['teacher/getLessons'];
    },
  },

  methods: {
    async getLessons() {
      this.loading = true;
      await this.$store.dispatch('teacher/lessons');
      this.loading = false;

    },
    openModal() {
      this.showModal = true;
    },
    toDetails({id}) {
      this.$router.push('/profe/asignaturas/' + id);
      },
    fetchSubjects() {
      this.getLessons();
    }
  },
  created() {
    this.getLessons();
    this.headers.unshift(
      {
        text: 'Nombre',
        align: 'start',
        value: 'name',
        sortable: false,
      },
      {
        text: 'Descripción',
        align: 'start',
        value: 'description',
        sortable: false,
      },
      {
        text: 'Año',
        value: 'year',
        sortable: false
      },
      {
        text: 'Periodo',
        value: 'period',
        sortable: false
      },
      {
        text: 'Ver',
        value: 'actions',
        sortable: false
      },


    )
  }
}
</script>

<style scoped>
.image-container {
  width: 100%;
  height: 500px; /* Set a fixed height for the image container */
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
}
.contained-image {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}
</style>
