<template>
    <v-dialog v-model="dialog" persistent max-width="80%" style="overflow-y: hidden;">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="my-4 responsive-button" height="80px" width="90%" color="primary" v-bind="attrs" v-on="on">
          Ingresar Estudiante
        </v-btn>
      </template>
  
      <v-container fluid>
        <v-card>
          <div class="d-flex align-center justify-space-between primary white--text">
            <v-card-title primary white--text>
              Ingresa la informacion del usuario
            </v-card-title>
            <v-btn class="mr-5 text-white " color="red" @click="closeDialog"> X </v-btn>
          </div>
          <v-card-text>
                <v-form ref="registerForm" v-model="valid" lazy-validation @submit.prevent="register">
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field v-model="formCreate.name" :rules="[rules.required]" label="Nombre" maxlength="20" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field v-model="formCreate.lastName" :rules="[rules.required]" label="Apellido Paterno" maxlength="20" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field v-model="formCreate.secondLastName" :rules="[rules.required]" label="Apellido Materno" maxlength="20" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field v-model="formCreate.email" :rules="emailRules" label="E-mail" required></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field v-model="formCreate.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Contraseña" hint="Minimo 8 caracteres" counter @click:append="show1 = !show1"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="formCreate.passwordCheck" block :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, passwordMatchCreate]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Confirmar Contraseña" counter @click:append="show1 = !show1"></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex ml-auto" cols="12" sm="3" xsm="12">
                      <v-btn v-if="formValidCreate" :loading="loading" x-large block :disabled="!valid" color="deep-purple lighten-1" @click="register">Crear</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
        </v-card>
  

      </v-container>
    </v-dialog>
  </template>
  
  <script>
  import axios from "axios";
  import Swal from "sweetalert2";


  export default {
    data() {
      return {
        loadingReset: null,
        valid: true,
        checkbox: true,
        loading: null,
        snackbar: false,
        snackbarText: "",
        snackbarColor: "",
        dialog: false,
        loginEmailRules: [
            v => !!v || "Campo Requerido",
            v => /.+@.+\..+/.test(v) || "E-mail debe ser válido"
        ],
        emailRules: [
            v => !!v || "Campo Requerido",
            v => /.+@.+\..+/.test(v) || "E-mail debe ser válido"
        ],

        show1: false,
        rules: {
            required: value => !!value || "Campo Requerido",
            min: v => (v && v.length >= 8) || "Min 8 caracteres"
        },
        formCreate: {
            name: '',
            lastName: '',
            secondLastName: '',
            email: '',
            password: '',
            passwordCheck: '',
            rol: 0,
        },
        
      };
    },
  
    computed: {
        passwordMatchCreate() {
            return () => this.formCreate.password === this.formCreate.passwordCheck || "Contraseñas deben coincidir";
        },
        formValidCreate() {
        if(this.formCreate.name === '' || this.formCreate.lastName === '' ||
            this.formCreate.secondLastName === '' || this.formCreate.email === '' || this.formCreate.password === '' ||
            this.formCreate.password !== this.formCreate.passwordCheck
        ) {
            return false;
        }
        return true;

        }
    },
  
    methods: {
        async register() {
        this.loading = true;
        if (
            this.formCreate.name === "" ||
            this.formCreate.lastName === "" ||
            this.formCreate.secondLastName === "" ||
            this.formCreate.email === null ||
            this.formCreate.password.length < 8
        ) {
            return;
        }

        await axios
            .post("createStudent", this.formCreate)
            .then(() => {
            this.loading = false;
            this.checkbox = true;
            return Swal.fire("", "Cuenta creado con éxito!", "success");
            })
            .catch(() => {
            this.loading = false;
            return Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "El correo debe ser único.",
            });
            });


        },
  
      closeDialog() {
        this.dialog = false;
      },

  
      resetForm() {
        this.form = {
          name: "",
          lastName: "",
          email: "",
        };
        this.valid = false;
      },
    },
  
    created() {
    },
  };
  </script>


<style scoped>
  @media (max-width: 930px) {
    .responsive-button {
      font-size: 8px;
    }
  }

  @media (min-width: 930px) and (max-width: 1200px) {
    .responsive-button {
      font-size: 12px;
    }
  }

  @media (min-width: 1200px) {
    .responsive-button {
      font-size: 14px;
    }
  }
</style>