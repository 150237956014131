<template>
  <div class="mt-6">
    <v-row v-if="!loading && polls.length > 0">
      <v-col v-for="(poll, i) in polls" :key="i">
        <card-component
            :title="`Responder antes del: ${poll.limit}`"
            :description="poll.description"
            image="activity"
        >
          <template  v-slot:activities>
            <v-btn color="primary" @click="confirmAndGoToPoll(poll.id)">Realizar</v-btn>
          </template>
          <template v-slot:polls>
            <v-chip color="error" class="ma-2">
              Pendiente
            </v-chip>
          </template>
        </card-component>
      </v-col>
    </v-row>

    <loading-component v-if="loading"/>

    <v-row v-if="!loading && polls.length===0" style="height: 60vh" align="center">
      <v-col>
        <v-img height="60%" width="60%" :src="require('@/assets/images/undraw_empty_street_sfxm.svg')"></v-img>
        <p class="mt-5">Aún no tiene diagnósticos asignadas</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardComponent from "../../../../components/UI/CardComponent";
import LoadingComponent from "../../../../components/UI/LoadingComponent";
import Swal from "sweetalert2";

export default {
  props: ['loading'],
  components: { LoadingComponent, CardComponent },
  data() {
    return {};
  },
  computed: {
    polls() {
      return this.$store.getters['student/getStudentPollsNoReply'];
    },
  },
  methods: {
    confirmAndGoToPoll(id) {
      Swal.fire({
        title: '¿Deseas comenzar este diagnóstico?',
        text: "Una vez que comiences, deberás completarla.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, comenzar!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.goToPoll(id);
        }
      });
    },
    goToPoll(id) {
      this.$router.push('/estudiante/diagnostico/' + id);
    }
  },
};
</script>