<template>
  <div>
    <v-tabs  v-model="tab">
      <v-tab href="#tab-1">Texto</v-tab>
      <v-tab href="#tab-2">Banco Preguntas</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">

        <text-info/>

      </v-tab-item>

      <v-tab-item value="tab-2">

        <text-questions/>

      </v-tab-item>


    </v-tabs-items>


  </div>
</template>

<script>
import TextInfo from "./details/TextInfo";
import TextQuestions from "./details/TextQuestions";

export default {
  components: {TextQuestions, TextInfo},
  data () {
    return {
      tab: null,
    }
  },

}
</script>