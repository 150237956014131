import Vue from "vue";
import VueRouter from "vue-router";
import HomeScreen from "../pages/shared/HomeScreen";
import LoginScreen from "../pages/shared/LoginScreen";
import { teacherRoute } from "./teacherRoute";
import { studentRoute } from "./studentRoute";
import store from "../store";
import ResetPassword from "../pages/shared/ResetPassword";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", redirect: "/home" },
    { path: "/reset/:token", component: ResetPassword },
    {
      path: "/home",
      component: HomeScreen,
      name: "Home",
      async beforeEnter(to, from, next) {
        try {
          const token = store.getters.getToken;
          if (!token) {
            next();
          } else {
            await store.dispatch("userInfo");
            const userInfo = store.getters.getUserInfo;
            if (userInfo.rol === 0) {
              next("/estudiante");
            } else if (userInfo.rol === 1) {
              next("/profe");
            } else {
              next("/login");
            }
          }
        } catch (error) {
          next("/login");
        }
      },
    },
    {
      path: "/login",
      component: LoginScreen,
      name: "Login",
      async beforeEnter(to, from, next) {
        try {
          const token = store.getters.getToken;
          if (!token) {
            next();
          } else {
            await store.dispatch("userInfo");
            const userInfo = store.getters.getUserInfo;
            if (userInfo.rol === 0) {
              next("/estudiante");
            } else if (userInfo.rol === 1) {
              next("/profe");
            } else {
              next();
            }
          }
        } catch (error) {
          next();
        }
      },
    },
    teacherRoute,
    studentRoute,
    {
      path: "/:catchAll(.*)",
      name: "NotFound",
      redirect: "/home",
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
