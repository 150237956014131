<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between primary white--text">
      <div>
        Estudiantes parte de la asignatura
      </div>
      <div>
        <add-students @student-saved="getStudents" class="my-3 mt-6 v-flex mx-2 primary" />
      </div>
    </v-card-title>

    <v-text-field class="mx-4" v-model="search" append-icon="mdi-magnify" label="Buscar Estudiantes" single-line hide-details></v-text-field>

    <v-data-table height="300px" fixed-header  :search="search" v-model="selected" :headers="headers" :items="students" item-key="id" class="elevation-1">
      <template v-slot:no-data>
        <p>No tiene estudiantes en la asignatura.</p>
      </template>
    </v-data-table>

    <v-card-title class="justify-center">
      Agregar Estudiantes a la asignatura por medio de un Excel, creando sus perfiles de usuario automáticamente.
      <h4 class="text-center">
        (Ver correo con contraseña predeterminada)
      </h4>
    </v-card-title>

   

    <v-row>
      <v-col cols="12">
        <v-file-input :disabled="loading" :loading="loading" type="file" label="Formato excel. Máximo 50 estudiantes por archivo. Formato: primera columna nombre completo del estudiante, segunda columna email" @change="addStudents" v-model="excel">
        </v-file-input>
      </v-col>
      <v-col class="text-center" cols="12" v-if="loading">
        <p class="text-center">Añadiendo estudiantes. Esto puede tardar unos minutos ...</p>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-card-actions class="justify-center mt-4">
     
    </v-card-actions>

  </v-card>
</template>

<script>
import axios from 'axios';
import AddStudents from './AddStudents.vue';
import Swal from 'sweetalert2';

export default {
  components: {
    AddStudents
  },
  data: () => ({
    color: 'success',
    text: '',
    snackbar: null,
    loading: false,
    excel: null,
    search: '',
    selected: [],
    headers: [
      {
        text: 'Nombre',
        align: 'start',
        value: 'name',
      },
      { text: 'Apellido Paterno', value: 'lastName' },
      { text: 'Apellido Materno', value: 'secondLastName' },
      { text: 'E-mail', value: 'email' },
    ],
    errorMessages: []
  }),

  computed: {
    students() {
      return this.$store.getters['teacher/getStudentsInLesson'];
    },
  },

  created() {
    this.getStudents();
  },

  methods: {
    async addStudents() {
      if (!this.excel) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Debe seleccionar un archivo Excel.',
        });
        return;
      }

      this.loading = true;
      this.errorMessages = [];
      try {
        const blob = new Blob([this.excel], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const formData = new FormData();
        formData.append('excel', blob);
        formData.append('id', this.$route.params.id);
        const response = await axios.post('teacher/addStudent', formData);

        if (response.data.ok) {
          this.getStudents();
          Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: 'Estudiantes añadidos correctamente.',
          });
          if (response.data.errors.length) {
            let errorList = '<ul>';
            response.data.errors.forEach(error => {
              errorList += `<li>Fila: ${error.row} - Email: ${error.email} - Error: ${error.message}</li>`;
            });
            errorList += '</ul>';

            Swal.fire({
              icon: 'error',
              title: 'Errores al añadir estudiantes',
              html: errorList,
            });
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.message,
          });
        }
      } catch (e) {
        console.log(e);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: e.response.data.message,
        });
      } finally {
        this.loading = false;
        this.excel = null;
      }
    },

    async getStudents() {
      let id = this.$route.params.id;
      await this.$store.dispatch('teacher/studentsInLesson', id);
    },

  },
}
</script>