export default {
  setStudentInLessons(state, payload) {
    state.studentsInLesson = payload;
  },
  setActivitiesInLesson(state, payload) {
    state.activitiesInLesson = payload;
  },
  setQuestions(state, payload) {
    state.questions = payload;
  },
  setQuestion(state, payload) {
    state.question = payload;
  },
  setAnswers(state, payload) {
    state.answers = payload;
  },
  async setLessons(state, payload) {
    state.lessons = payload;
  },
  async setTexts(state, payload) {
    state.texts = payload;
  },
  async setLesson(state, payload) {
    state.lesson = payload;
  },
  async setPoll(state, payload) {
    state.polls = payload;
  },
  setPollActivities: (state, pollsActivityInLesson) => {
    state.pollsActivityInLesson = pollsActivityInLesson;
  },
};
