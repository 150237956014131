<template>
  <v-card
      width="100%"
      outlined style="border: 0.2px solid #333;"
  >
    <v-card-title class="primary white--text" >Datos </v-card-title>
    <v-spacer></v-spacer>
    <v-card-text>
      <v-text-field
          disabled
          label="Nombre Asignatura"
          v-model="name"
      />
      <v-text-field
          disabled
          label="Descripción"
          v-model="description"
      />
      <v-text-field
          disabled
          type="number"
          label="Año"
          v-model="year"
      />
      <v-text-field
          disabled
          label="Período"
          v-model="period"
      />
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  data () {
    return {
      name: '',
      description: '',
      image: '',
      period: '',
      year: '',
      edit: false
    }
  },
  methods: {
    async getLesson() {
      const id = this.$route.params.id;
      await this.$store.dispatch('teacher/lesson', id);
      const lesson = this.$store.getters['teacher/getLesson'];
      this.name = lesson.name;
      this.description = lesson.description;
      this.image = lesson.image;
      this.period = lesson.period;
      this.year= lesson.year;
    },
  },
  async created() {
    await this.getLesson();
  }
}
</script>
