<template>
  <v-dialog persistent v-model="dialog">
    <v-container fluid v-if="pollActivity">
      <v-card max-height="auto">
        <v-card-title class="d-flex align-center justify-space-between primary white--text">
          <div class="d-flex">
            Diagnóstico {{ pollActivity.id }} del Texto 
            <template v-if="text && text.title">
              {{ text.title }}
            </template>
            <template v-else>
              (Sin título)
            </template>
          </div>
          <div class="d-flex justify-center align-center">
            <v-chip :color="getStatusClass(pollActivity)" class="text-white">
              {{ new Date(pollActivity.limit) > new Date() ? 'Activo' : 'Terminado' }}
            </v-chip>
            <v-btn class="mt-2 ml-3" color="red" @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-row class="d-flex ma-4">
          <v-col cols="12" md="6" class="d-flex flex-column outlined">
            <div class="d-flex justify-space-between mt-1 statistic-card">
              <div>
                <h2>
                  {{ formatTime(timeSpentAverage) }}
                </h2>
                   <h3>
                  Tiempo Total promedio
                </h3>
              </div>
              <v-card-text class="pa-4">
                <p><strong>Descripción:</strong> {{ description }}</p>
                <p><strong>Fecha Límite:</strong> {{ new Date(limit).toLocaleDateString() }}</p>
              </v-card-text>
            </div>
            <div class="scrollable-content">
              <h3 v-if="replies.length > 1" class="ml-2">Respuestas: {{ replies.length }} estudiantes contestaron </h3>
              <h3 v-if="replies.length == 1" class="ml-2">Respuestas: 1 estudiante contestó </h3>
              <h3 v-else class="ml-2">Respuestas: Ningún estudiante contestó </h3>
              <div v-for="(section, sectionIndex) in sections" :key="sectionIndex" class="d-flex mt-1 flex-column border">
                <v-card>
                  <v-card-title style="background-color: rgba(63, 81, 181, 0.8); color: black;">
                    <p class="section-title">{{ section.title }}</p>
                  </v-card-title>
                  <v-divider></v-divider>
                  <div v-for="(question, questionIndex) in section.questions" :key="questionIndex" class="d-flex ml-3 mt-1 flex-column border">
                    <h3 color="teal" class="text-justify mt-3 pr-3">
                      .-{{ question.description }} 
                    </h3>
                    <div v-if="question.type === 1">
                      <p><strong>Tipo:</strong> Elección de Respuestas Globales</p>
                      <div class="d-flex flex-column">
                        <div v-for="(alternative, j) in getSectionAnswers(section.title)" :key="j">
                          <div class="d-flex justify-space-between">
                            <div class="d-flex statistic-card">
                              {{ j + 1 }})
                              <h4 class="ml-2 text-left">
                                {{ alternative.description }}
                              </h4>
                            </div>
                            <div class="statistic-card justify-center align-content-center">
                              <v-btn @click="openModal('alternative', question.id, alternative.id)">
                                <h4 class="text-left">
                                  {{ getAnswerCount(question.id, alternative.id) }} Respuestas
                                </h4>
                              </v-btn>
                            </div>
                            <div class="statistic-card justify-center align-content-center">
                              <v-progress-linear
                                :value="getAnswerPercentage(question.id, alternative.id)"
                                color="primary"
                                height="20"
                                rounded
                                striped
                              >
                                {{ getAnswerPercentage(question.id, alternative.id) }}%
                              </v-progress-linear>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> 
                    <div v-else-if="question.type === 2">
                      <p><strong>Tipo:</strong> Sí o No</p>
                      <div class="d-flex flex-column">
                        <div v-for="(alternative, j) in getSectionAnswersYESNO(section.title,question)" :key="j">
                          <div class="d-flex justify-space-between">
                            <div class="d-flex statistic-card">
                              {{ j + 1 }})
                              <h4 class="ml-2 text-left">
                                {{ alternative.description }}
                              </h4>
                            </div>
                            <div class="statistic-card justify-center align-content-center">
                              <v-btn @click="openModal('alternative', question.id, alternative.id)">
                                <h4 class="text-left">
                                  {{ getAnswerCount(question.id, alternative.id) }} Respuestas
                                </h4>
                              </v-btn>
                            </div>
                            <div class="statistic-card justify-center align-content-center">
                              <v-progress-linear
                                :value="getAnswerPercentage(question.id, alternative.id)"
                                color="primary"
                                height="20"
                                rounded
                                striped
                              >
                                {{ getAnswerPercentage(question.id, alternative.id) }}%
                              </v-progress-linear>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="question.type === 3">
                      <p><strong>Tipo:</strong> Desarrollo</p>
                      <v-btn @click="openModal('development', question.id, false)">
                        <h4 class="text-left">
                          Respuestas abiertas
                        </h4>
                      </v-btn>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>  
          </v-col>
          <v-col cols="12" md="6" class="outlined">
            <v-card class="flex-grow-1">
              <v-card-title class="d-flex justify-space-between align-center">
                <span>Gráfico de Término</span>
                <EditPollActivity :pollActivity="pollActivity" @poll-activity-updated="loadData" />
              </v-card-title>
              <DoughnutChart v-show="chartDonutData" :chartData="chartDonutData" :options="chartDonutOptions" @click.native="handleDoughnutClick"></DoughnutChart>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <StudentModal :isPollActivity="true" :show.sync="showModal" :students="modalStudents" :title="modalTitle" />
    <ModalResponses :isPollActivity="true" v-if="showAlternativeModal" :showModal.sync="showAlternativeModal" :questionId="selectedQuestionId" :alternativeId="selectedAlternativeId" modalType="alternative" :replies="replies" />
    <ModalResponses :isPollActivity="true" v-if="showDevelopmentModal" :showModal.sync="showDevelopmentModal" :questionId="selectedQuestionId" :alternativeId="selectedAlternativeId" modalType="development" :replies="replies" />
  </v-dialog>
</template>

<script>
import axios from 'axios';
import DoughnutChart from '@/components/UI/DoughnutChart.vue';
import StudentModal from '../UI/StudentModal.vue';
import EditPollActivity from '@/components/teacher/forms/EditPollActivity.vue';
import ModalResponses from '@/pages/teacher/lesson/details/ModalResponses.vue';

export default {
  props: ['pollActivity', 'dialog'],
  components: { DoughnutChart, ModalResponses, EditPollActivity, StudentModal },
  model: {
    prop: 'dialog',
    event: 'change',
  },
  data() {
    return {
      timeSpentAverage: null,
      chartDonutData: null,
      chartDonutOptions: { responsive: true },
      replies: [],
      text: null,
      timeSpent: [],
      modalStudents: null,
      modalTitle: "",
      showModal: false,
      selectedQuestionId: null,
      selectedAlternativeId: null,
      sections: [],
      questions: [],
      limit: null,
      description: null,
      completionStatus: null,
      answers: [],
      showDevelopmentModal: false,
      showAlternativeModal: false,
    };
  },
  watch: {
    dialog(newValue) {
      if (newValue === true) {
        this.loadData();
      } else {
        this.resetData();
      }
    },
    pollActivity: {
      handler(newValue) {
        if (newValue) {
          this.text = newValue.text || null;
          this.questions = newValue.questions || [];
          this.description = newValue.description || null;
          this.limit = newValue.limit || null;
          this.answers = newValue.answers || [];
        } else {
          this.resetData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    resetData() {
      this.timeSpentAverage = null;
      this.chartDonutData = null;
      this.replies = [];
      this.timeSpent = [];
      this.sections = [];
      this.questions = [];
      this.limit = null;
      this.description = null;
      this.completionStatus = null;
      this.answers = [];
      this.showDevelopmentModal = false;
      this.showAlternativeModal = false;
      this.text = null;
    },
    async loadData() {
      if (!this.pollActivity) return;
      
      await Promise.all([
        this.getReplies(),
        this.getActivityCompletionStatus(),
      ]);
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = (seconds % 60).toFixed(1);
      return minutes > 0 ? `${minutes}m ${remainingSeconds}s` : `${remainingSeconds}s`;
    },
    getSectionAnswers(sectionTitle) {
      return this.answers.filter(answer => answer.section === sectionTitle && answer.description !== 'Sí' && answer.description !== 'No');
    },
    getSectionAnswersYESNO(sectionTitle, question) {
      const answers = this.answers.filter(answer => answer.section === sectionTitle && (answer.description === 'Sí' || answer.description === 'No'));
      if (answers.length === 0) {
        return [
          { description: 'Sí', id: `yes_${question.id}` },
          { description: 'No', id: `no_${question.id}` }
        ];
      }
      return answers;
    },
    renderCharts() {
      if (this.chartDonutData) {
        this.getDonutChart();
      }
    },
    getStatusClass(item) {
      return new Date(item.limit) > new Date() ? 'blue' : 'red';
    },
    getAnswerCount(questionId, answerId) {
      return this.replies.reduce((count, reply) => {
        const answers = Object.values(reply.answer); // Obtener un array de las respuestas
        const answer = answers.find(a => a.questionId === questionId && a.answerId === answerId);
        return count + (answer ? 1 : 0);
      }, 0);
    },
    getAnswerPercentage(questionId, answerId) {
      const totalReplies = this.replies.length;
      const answerReplies = this.getAnswerCount(questionId, answerId);
      return totalReplies > 0 ? ((answerReplies / totalReplies) * 100).toFixed(1) : 0;
    },
    openModal(type, questionId, alternativeId) {
      this.selectedAlternativeId = alternativeId;
      this.selectedQuestionId = questionId;
      if (type === 'alternative') {
        this.showAlternativeModal = true;
      } else if (type === 'development') {
        this.showDevelopmentModal = true;
      }
    },
    handleDoughnutClick(event, array) {
      if (array.length > 0) {
        const index = array[0]._index;
        const label = this.chartDonutData.labels[index];

        let students = [];
        if (label === 'Terminado') {
          students = this.completionStatus.completed.students;
          this.modalTitle = 'Estudiantes que han terminado el diagnóstico';
        } else {
          students = this.completionStatus.remaining.students;
          this.modalTitle = 'Estudiantes que aún no han terminado el diagnóstico';
        }
        this.modalStudents = students.map(student => {
          const reply = this.replies.find(reply => reply.user_id === student.id);
          const time = reply ? reply.time : 0;
          const points = reply ? reply.points : 0;
          return {
            id: student.id,
            name: student.name + ' ' + student.lastName,
            time: time,
            points: points  
          };
        });

        this.showModal = true;
      }
    },
    getDonutChart() {
      this.chartDonutData = {
        labels: ['Terminado', 'Faltantes'],
        datasets: [
          {
            data: [this.completionStatus.completed.count, this.completionStatus.remaining.count],
            backgroundColor: ['#36A2EB', '#FF6384'],
            borderColor: ['#36A2EB', '#FF6384']
          }
        ]
      };
      this.chartDonutOptions = {
        maintainAspectRatio: false,
        onClick: this.handleDoughnutClick
      };
    },
    async getReplies() {
      await axios.get(`teacher/poll_activity/replies/${this.pollActivity.id}`)
        .then(response => {
          if (response.data.ok) {
            this.replies = response.data.replies.map(reply => ({
              ...reply,
              answer: JSON.parse(reply.answer)
            }));

            // Asignar sección predeterminada si no está definida
            this.questions = this.questions.map(question => ({
              ...question,
              section: question.section || 'Sección Predeterminada'
            }));

            this.answers = this.answers.map(answer => ({
              ...answer,
              section: answer.section || 'Sección Predeterminada'
            }));

            // Organizar las preguntas en secciones
            this.questions.forEach((question) => {
              const sectionIndex = this.sections.findIndex(section => section.title === question.section);
              if (sectionIndex === -1) {
                this.sections.push({ title: question.section, questions: [question] });
              } else {
                this.sections[sectionIndex].questions.push(question);
              }
            });

            this.timeSpent = this.replies.map(reply => reply.time);
            this.timeSpentAverage = (this.timeSpent.reduce((a, b) => a + b, 0) / this.timeSpent.length).toFixed(1);
          } else {
            console.error("No replies found:", response.data.error);
          }
        })
        .catch(error => {
          console.error("Error fetching replies:", error);
        });
    },
    async getActivityCompletionStatus() {
      await axios.get(`teacher/poll_activity/getPollActivityCompletionStatus/${this.pollActivity.id}`)
        .then(response => {
          this.completionStatus = response.data;
          this.getDonutChart();
          this.renderCharts();
        })
        .catch(error => {
          console.error("Error fetching completion status:", error);
        });
    },
    closeDialog() {
      this.resetData();
      this.$emit('close-dialog');
    }
  },
  created() {
    this.text = this.pollActivity && this.pollActivity.text ? this.pollActivity.text : null;
  }
};
</script>


<style scoped>
.statistic-card {
  flex: 1;
  margin: 4px;
  padding: 10px;
  border-radius: 4px;
  background-color: #f5f5f5;
}
.scrollable-content {
  max-height: 400px;
  height: 90%;
  overflow-y: auto;
}
.outlined {
  border: 0.2px solid #333;
  border-radius: 8px;
}
</style>