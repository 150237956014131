<template>
  <div>
    <v-tabs  v-model="tab" class="mt-7">
      <v-tab v-for="(_, i) in replies" :href="`#${i}`" :key="i">Pregunta {{ i + 1 }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(reply, index) in replies" :key="index" :value="`${index}`">
        <bar-chart :data="reply" :activity-id="activityId"/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import axios from "axios";
import BarChart from "../../../components/UI/BarChart";

export default {
  components: {BarChart},
  data() {
    return {
      replies: [],
      tab: null,
      activityId: null
    }
  },
  methods: {
    getReplies() {
      this.activityId = this.$route.params.id;
      axios.get('activity/' + this.activityId)
          .then( response => {
            const { questions } = response.data;
            this.replies = questions;
          })
    }
  },
  created() {
    this.getReplies();
  }
}
</script>