<template>
  <div>
    <v-btn @click="openDialog" class="white--text" color="deep-purple accent-4">
      Asociar una imagen a una palabra
    </v-btn>

    <v-dialog width="80%" v-model="dialog">
      <v-card>
        <v-card-title primary white--text>
          Agregar palabras al texto, aquí se muestran todas las palabras asociadas en el texto.
        </v-card-title>
        <v-form @submit.prevent="submit" ref="form" lazy-validation>
          <v-card-text>
            <v-row>
              <v-col cols="10">
                <v-row v-for="(word, i) in form.words" :key="i">
                  <v-col cols="7">
                    <v-text-field
                      v-model="word.word"
                      label="Palabras (debe aparecer en el texto completo, fijarse también en mayúsculas y tildes)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-file-input
                      label="Imagen"
                      v-model="word.image"
                      :show-size="true"
                      accept="image/*"
                      :prepend-icon="word.image ? 'mdi-check' : 'mdi-camera'"
                      :placeholder="word.image ? 'Ya tiene imagen' : 'Seleccione una imagen'"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="1">
                    <v-btn @click="() => form.words.splice(i, 1)" fab x-small class="mt-3">
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="1">
                    <v-btn @click="showImage(word.image)" fab x-small class="mt-3">
                      <v-icon color="blue">mdi-eye</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="d-flex justify-center" cols="2">
                <v-btn @click="() => form.words.push({ word: '', image: null })">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" @click="cancel">Cancelar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit">Guardar palabras</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <div>
        <v-snackbar :color="color" v-model="snackbar">
          {{ snackText }}.
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-dialog>

    <v-dialog v-model="imageDialog" max-width="500px">
      <v-card>
        <v-card-title>Imagen asociada</v-card-title>
        <v-card-text class="text-center">
          <v-img :src="getImageUrl(selectedImage)" ></v-img>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="imageDialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    text: {
      type: String,
    },
    paragraphs: {
      type: Array,
      required: true
    },
    textId: {
      type: String,
      required: true
    },
    images: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      snackText: '',
      color: '',
      snackbar: false,
      dialog: false,
      imageDialog: false,
      selectedImage: null,
      form: {
        words: []
      },
      fullText: ''
    };
  },
  methods: {
    setSnackBar(color, text, snack) {
      this.color = color;
      this.snackText = text;
      this.snackbar = snack;
    },
    resetForm() {
      this.form = {
        words: [
          {
            word: '',
            image: null
          }
        ]
      };
    },
    async submit() {
      for (let word of this.form.words) {
        if (word.word === '') {
          this.setSnackBar('red', 'No se permiten palabras vacías.', true);
          return;
        }
        const inText = this.fullText.includes(word.word);
        if (!inText) {
          this.setSnackBar('red', 'Alguna de las palabras no pertenece al texto completo', true);
          return;
        }
      }

      const formData = new FormData();
      this.form.words.forEach((wordImage, index) => {
        formData.append(`words[${index}][word]`, wordImage.word);

        if (typeof wordImage.image === 'string') {
          formData.append(`words[${index}][image]`, wordImage.image);
        } else if (wordImage.image instanceof File) {
          formData.append(`words[${index}][image]`, wordImage.image);
        }
      });

      console.log('formData', Object.fromEntries(formData.entries()));

      try {
        await axios.post(`/teacher/text/addImageWord/${this.textId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.setSnackBar('green', 'Palabra/s guardadas con éxito', true);
        this.$emit('words-saved');  // Emitir evento personalizado
      } catch (error) {
        this.setSnackBar('red', 'Error al guardar la palabra/s', true);
      }

      this.resetForm();
      this.dialog = false;
    },
    cancel() {
      this.resetForm();
      this.dialog = false;
    },
    showImage(image) {
      this.selectedImage = image;
      this.imageDialog = true;
    },
    getImageUrl(image) {
      if (image instanceof File) {
        return URL.createObjectURL(image);
      }
      return image ? `${image}` : '';
    },
    openDialog() {
      this.dialog = true;
      const wordsImages = JSON.parse(this.images);
      this.form.words = Object.entries(wordsImages).map(([word, image]) => ({ word, image }));
    }
  },
  created() {
    this.fullText = this.paragraphs.map(paragraph => paragraph.description).join(' ');
  }
};
</script>