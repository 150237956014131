<template>
    <v-dialog v-model="dialog" persistent max-width="80%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="my-4" color="primary" outlined small v-bind="attrs" v-on="on" @click="openDialog">
          <v-icon dark>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <v-container fluid>
        <v-card>
          <v-card-title class="primary white--text">Editar Actividad de Diagnóstico</v-card-title>
          <v-form @submit.prevent="submit" ref="form" lazy-validation>
            <v-card-text>
              <date-component :date="form.limit" @date="form.limit = $event" />
              <v-textarea class="mt-5" prepend-icon="mdi-format-text-variant" v-model="form.description" auto-grow filled color="deep-purple" label="Descripción" rows="3"></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-btn color="error" @click="cancel"> Cancelar </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit">Guardar</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-container>
    </v-dialog>
  </template>
  
  <script>
  import axios from "axios";
  import Swal from "sweetalert2";
  import DateComponent from "../../UI/DateComponent";
  export default {
    components: { DateComponent },
    props: {
      pollActivity: {
        type: Object,
      },
    },
    data() {
      return {
        dialog: false,
        form: {
          limit: this.pollActivity.limit ? this.formatDate(this.pollActivity.limit) : null,
          description: this.pollActivity.description || "",
        },
      };
    },
    methods: {
      async submit() {
        const isDateValid = new Date(this.form.limit) >= new Date(this.pollActivity.limit);
        if (!isDateValid || this.form.description.trim() === "" || !this.form.limit) {
          Swal.fire('Error', 'La descripción no puede estar vacía y debe seleccionar una fecha de límite.', 'error');
          return;
        }
  
        await Swal.fire({
          title: "¿Desea editar el diagnóstico?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí!",
          cancelButtonText: "Volver",
        }).then(async (result) => {
          if (result.isConfirmed) {
            let data = {
              id: this.pollActivity.id,
              description: this.form.description,
              limit: this.form.limit,
            };
  
            const response = await axios.put("teacher/poll_activity/updatePollActivity", data);
  
            if (response.status === 200) {
              this.$emit('poll-activity-updated');
              await Swal.fire("Editada", "Diagnóstico editada con éxito", "success");
              this.dialog = false;
            } else {
              console.error("Error al actualizar el diagnóstico:", response.data.error);
              await Swal.fire("Error", "Error al editar el diagnóstico", "error");
            }
          }
        });
      },
      formatDate(date) {
        const formattedDate = new Date(date);
        return formattedDate.toISOString().substr(0, 10);
      },
      cancel() {
        this.resetForm();
        this.dialog = false;
      },
      resetForm() {
        this.form = {
          limit: this.formatDate(this.pollActivity.limit),
          description: this.pollActivity.description,
        };
      },
      openDialog() {
        this.dialog = true;
      },
    },
    created() {
      if (this.pollActivity) {
        this.form.limit = this.formatDate(this.pollActivity.limit);
        this.form.description = this.pollActivity.description;
      }
    },
  };
  </script>