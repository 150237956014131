<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="70%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="responsive-button"
          height="80px"
          width="80%"
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          Eliminar Asignaturas
        </v-btn>
      </template>
      <v-container fluid>
        <v-card>
          <v-card-title class="primary white--text">Eliminar Asignaturas</v-card-title>
          <v-form @submit.prevent="submit" ref="form" lazy-validation>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="lessons"
                item-key="id"
                show-select
                v-model="selectedSubjects"
                class="elevation-1"
                :search="search"
              >
                <template v-slot:top>
                  <v-toolbar  flat>
                    <v-toolbar-title>Lista de Asignaturas</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar asignatura" single-line hide-details>
                    </v-text-field>

                  </v-toolbar>
                </template>
                <template v-slot:item.selected="{ item }">
                  <v-checkbox :input-value="selectedSubjects.includes(item)" @change="toggleSelect(item)" />
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-btn color="error" @click="cancel">Cancelar</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit">Eliminar Asignaturas</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-container>
    </v-dialog>
  </v-row>
 
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  props: {
    lessons: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      selectedSubjects: [],
      search: '',
      selected: [],
      loading: false,
      headers: [
        { text: 'Nombre', align: 'start', value: 'name', sortable: false },
        { text: 'Descripción', align: 'start', value: 'description', sortable: false },
        { text: 'Año', value: 'year', sortable: false },
        { text: 'Periodo', value: 'period', sortable: false }
      ]
    };
  },
  computed: {
    lessonsData() {
      return this.$store.getters['teacher/getLessons'];
    }
  },
  methods: {
    async getLessons() {
      this.loading = true;
      await this.$store.dispatch('teacher/lessons');
      this.loading = false;
    },
    async submit() {
      await Swal.fire({
        title: "¿Desea eliminar estas asignaturas?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí!",
        cancelButtonText: "Volver",
      }).then(async (result) => {
        if (result.isConfirmed) {

          let lessonIds = this.selectedSubjects.map(data => data.id);

          if (lessonIds.length === 0) {
            Swal.fire('', 'Debe ingresar alguna asignatura!', 'error');
            return; // No hay lecciones seleccionadas para eliminar
          }

          try {
            await axios.delete('teacher/delete_lesson', { data: { lessonIds: lessonIds } });
            this.getLessons();
            Swal.fire('', 'Asignaturas eliminadas con éxito!', 'success');
            this.dialog = false; // Cierra el diálogo después de la eliminación exitosa
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrió un error al eliminar las asignaturas.'
            });
          }

        }
      });
    },
    cancel() {
      this.selectedSubjects = [];
      this.dialog = false;
    },
    toggleSelect(item) {
      const index = this.selectedSubjects.indexOf(item);
      if (index > -1) {
        this.selectedSubjects.splice(index, 1);
      } else {
        this.selectedSubjects.push(item);
      }
    }
  },
  created() {
    this.getLessons();
  }
};
</script>



<style scoped>
  @media (max-width: 1150px) {
    .responsive-button {
      font-size: 8px;
    }
  }

  @media (min-width: 1150px) and (max-width: 1280px) {
    .responsive-button {
      font-size: 12px;
    }
  }

  @media (min-width: 1200px) {
    .responsive-button {
      font-size: 13px;
    }
  }
</style>