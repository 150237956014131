<template>
  <div>
    <!-- Mostrar el texto asociado si aún no se ha terminado la lectura -->
    <v-card v-if="!finishReading && text" class="mt-4 pa-4">
      <v-card-title>
        <p class="text-title"><strong>{{ text.title }}</strong></p>
      </v-card-title>
      <v-card-subtitle>
        <p>{{ text.author }}</p>
        <p>{{ text.publicationYear }}</p>
      </v-card-subtitle>
      <v-card-text class="text-content">
        <div v-for="(paragraph, index) in text.paragraphs" :key="index">
          {{ paragraph.description }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="finishReadingText" text color="deep-purple accent-4">Terminar lectura</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Mostrar las preguntas si no hay texto o después de que se haya terminado la lectura -->
    <div v-if="finishReading || !text" class="mt-4 pa-4">
      <v-card>
        <v-card-title class="mb-2 primary ">
          <p class="text-title">Descripción: {{ pollActivity.description }}</p>
        </v-card-title>
      </v-card>
      <div v-for="(section, sectionIndex) in sections" :key="sectionIndex" class="section-container">
        <v-card>
          <v-card-title style="background-color: rgba(63, 81, 181, 0.8); color: black;">
            <p class="section-title">{{ section.title }}</p>
          </v-card-title>
          <v-divider></v-divider>
          <div v-for="(question, questionIndex) in section.questions" :key="questionIndex" class="question-container pa-4">
            <v-card class="mt-4">
              <v-form>
                <v-card-text>
                  <v-card-title>
                    <p>{{ question.description }}</p>
                  </v-card-title>
                  <v-container fluid>
                    <!-- Mostrar diferentes tipos de preguntas -->
                    <template v-if="question.type === 1">
                      <v-radio-group v-model="studentReply[question.id].answerId" @change="handleAnswerChange(question.id, question.type)">
                        <v-radio :disabled="finish" v-for="(alternative, i) in getSectionAnswers(section.title)" :key="i"
                          :label="alternative.description" :value="alternative.id">
                          {{ alternative.description }}
                        </v-radio>
                      </v-radio-group>
                    </template>
                    <template v-else-if="question.type === 2">
                      <v-radio-group v-model="studentReply[question.id].answerId" @change="handleAnswerChange(question.id, question.type)">
                        <v-radio :disabled="finish" v-for="(alternative, i) in getSectionAnswersYESNO(section.title,question)" :key="i"
                          :label="alternative.description" :value="alternative.id">
                          {{ alternative.description }}
                        </v-radio>
                      </v-radio-group>
                    </template>
                    <template v-else-if="question.type === 3">
                      <v-textarea v-model="studentReply[question.id].answerText" @input="handleAnswerChange(question.id, question.type)" auto-grow filled color="deep-purple" rows="3">
                      </v-textarea>
                    </template>
                  </v-container>
                </v-card-text>
                <v-card-actions  v-if="showError[question.id]">
                  <v-alert type="error" class="mt-2">Debe responder todas las preguntas de esta sección.</v-alert>
                </v-card-actions>
              </v-form>
            </v-card>
          </div>
        </v-card>
      </div>
      <v-card-actions class="d-flex justify-space-between ">
        <v-btn @click="submit" v-if="hasUnansweredQuestions()" text color="red">
          Debe responder todas las preguntas para enviar el diagnóstico.
        </v-btn>
        <v-btn @click="submit" text color="deep-purple accent-4">
          Terminar Diagnóstico
        </v-btn>
      </v-card-actions>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      pollActivity: {},
      studentReply: {},
      answers: [],
      sections: [],
      text: null,
      finish: false,
      finishReading: false,
      loading: false,
      points: 0,
      totalPoints: 0,
      startTime: null,
      timeSpent: 0,
      showError: {}
    };
  },
  methods: {
    canSendForm() {
      return this.sections.every(section =>
        section.questions.every(question => {
          const reply = this.studentReply[question.id];
          if (question.type === 3) {
            return reply && reply.answerText && reply.answerText.trim() !== '';
          } else {
            return reply && reply.answerId !== undefined;
          }
        })
      );
    },
    getSectionAnswers(sectionTitle) {
      return this.answers.filter(answer => answer.section === sectionTitle && answer.description !== 'Sí' && answer.description !== 'No');
    },
    getSectionAnswersYESNO(sectionTitle, question) {
      const answers = this.answers.filter(answer => answer.section === sectionTitle && (answer.description === 'Sí' || answer.description === 'No'));
      if (answers.length === 0) {
        return [
          { description: 'Sí', id: `yes_${question.id}` },
          { description: 'No', id: `no_${question.id}` }
        ];
      }
      return answers;
    },
    handleAnswerChange(questionId, type) {
      const reply = this.studentReply[questionId] || { questionId: questionId };
      if (type === 3) {
        reply.answerText = this.studentReply[questionId].answerText;
      } else {
        reply.answerId = this.studentReply[questionId].answerId;
      }
      this.$set(this.studentReply, questionId, reply);
      this.saveToLocalStorage();
    },
    finishReadingText() {
      this.finishReading = true;
      this.startTime = Date.now();
      this.saveToLocalStorage();
    },
    saveToLocalStorage() {
      const data = {
        studentReply: this.studentReply,
        finishReading: this.finishReading,
        startTime: this.startTime,
      };
      localStorage.setItem(`pollActivity_${this.$route.params.id}`, JSON.stringify(data));
    },
    loadFromLocalStorage() {
      const data = localStorage.getItem(`pollActivity_${this.$route.params.id}`);
      if (data) {
        const parsedData = JSON.parse(data);
        this.studentReply = parsedData.studentReply || {};
        this.finishReading = parsedData.finishReading || false;
        this.startTime = parsedData.startTime || null;
      }
    },
    hasUnansweredQuestions(section = null) {
      if (section) {
        return section.questions.some(question => {
          const reply = this.studentReply[question.id];
          if (question.type === 3) {
            return !(reply && reply.answerText && reply.answerText.trim() !== '');
          } else {
            return !(reply && reply.answerId !== undefined);
          }
        });
      }
      return this.sections.some(section => this.hasUnansweredQuestions(section));
    },
    async submit() {
      this.showError = {};
      let hasError = false;

      this.sections.forEach(section => {
        section.questions.forEach(question => {
          const reply = this.studentReply[question.id];
          if (question.type === 3) {
            if (!reply || !reply.answerText || reply.answerText.trim() === '') {
              this.$set(this.showError, question.id, true);
              hasError = true;
            }
          } else {
            if (!reply || reply.answerId === undefined) {
              this.$set(this.showError, question.id, true);
              hasError = true;
            }
          }
        });
      });

      if (hasError) {
        Swal.fire({
          title: "Debe responder todas las preguntas para enviar el diagnóstico.",
          icon: "warning",
          confirmButtonText: "OK"
        });
        return;
      }

      this.points = Object.values(this.studentReply).reduce((acc, reply) => {
        const question = this.questions.find(q => q.id === reply.questionId);
        if (question) {
          if (question.type === 1) {
            const answer = this.answers.find(a => a.id === reply.answerId && a.section === question.section);
            return acc + (answer ? answer.points : 0);
          } else if (question.type === 2) {
            const answer = this.answers.find(a => a.description === (reply.answerId ? 'Sí' : 'No') && a.section === question.section);
            return acc + (answer ? answer.points : 0);
          }
        }
        return acc;
      }, 0);

      this.timeSpent = Math.round((Date.now() - this.startTime) / 1000);
      let id = this.$route.params.id;

      console.log('Student Reply:', this.studentReply); // Depuración
      console.log('Points:', this.points); // Depuración
      console.log('Time Spent:', this.timeSpent); // Depuración
      
      await Swal.fire({ 
        title: "¿Desea terminar la evaluación?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí!",
        cancelButtonText: "Volver",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await axios.post("student/poll_reply/" + id, {
            answer: JSON.stringify(this.studentReply),
            points: this.points,
            time: this.timeSpent
          });
          await Swal.fire("Completada!", "Las respuestas han sido contestadas", "success");
          this.finish = true;
          localStorage.removeItem(`pollActivity_${id}`);
          this.$router.push('/estudiante/asignaturas');
        }
      });
    },
  },
  async created() {
    this.loadFromLocalStorage();
    this.loading = true;
    let id = this.$route.params.id;
    try {
      const response = await axios.get("student/poll_activity/" + id);
      console.log('Response Data:', response.data); // Depuración

      this.answers = response.data.pollActivity.answers;
      this.questions = response.data.pollActivity.questions;
      this.pollActivity = response.data.pollActivity;
      this.text = response.data.text && response.data.text.title ? response.data.text : null;

      // Agrupar preguntas por secciones
      this.questions.forEach((question) => {
        const sectionIndex = this.sections.findIndex(section => section.title === question.section);
        if (sectionIndex === -1) {
          this.sections.push({ title: question.section, questions: [question] });
        } else {
          this.sections[sectionIndex].questions.push(question);
        }
      });

      // Initialize studentReply with the structure based on question type if not loaded from localStorage
      this.sections.forEach(section =>
        section.questions.forEach(question => {
          if (!this.studentReply[question.id]) {
            this.studentReply[question.id] = { questionId: question.id };
            if (question.type === 3) {
              this.studentReply[question.id].answerText = '';
            }
          }
        })
      );

      if (!this.startTime) {
        this.startTime = Date.now();
      }

      this.loading = false;
      if (!this.text) {
        this.finishReading = true;
      }
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style scoped>
.text-title {
  font-size: 1.4em;
}

.text-content {
  text-align: justify;
  font-size: 1.2em;
}

.section-container {
  margin-bottom: 20px;
}

.question-container {
  margin-bottom: 15px;
}

.v-card-text p {
  font-size: 1.1em;
}

.v-card-title {
  background-color: #3f51b5;
  color: white;
  padding: 10px;
  border-radius: 5px;
}
</style>