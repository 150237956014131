<template>
  <v-container class="main" fluid>
    <v-row justify="center" align="center" align-content-lg="center" style="height: 100vh">
      <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
      >
      <div class="ml-8">
        <p style="font-size: 60px">Lector Entreno</p>
        <p class="text-justify">Proyecto LECTOENTRENO es un <strong>Programa de intervención lectora que busca mejorar la comprensión lectora de estudiantes de diferentes niveles educativos, incluyendo el universitario</strong>. Un grupo de investigación interdisciplinario integrado por  Mabel Urrutia, Pamela Guevara,  Esteban Pino, Karina Fuentes y Pedro Salcedo, académicos/as de la Universidad de Concepción.</p>
        <v-btn @click="changePage">Ingresar</v-btn>
      </div>
        
      </v-col>
      <v-col
          xl="6"
          lg="6"
          md="6"
          align="center"
      >
        <v-img
            height="80%"
            width="80%"
            src="https://i.imgur.com/nOvD1Js.png"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    changePage() {
      this.$router.push('login');
    }
  }
}
</script>

<style scoped>
.main {
  background-color: #FF3CAC;
  background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
  height: 100vh;
}
</style>