export default {
  studentsInLesson: [],
  activitiesInLesson: [],
  questions: [],
  question: [],
  answers: [],
  lessons: [],
  lesson: null,
  texts: [],
  polls: [],
  pollsActivityInLesson: [],
};
