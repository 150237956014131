export default {
    setStudentLessons(state, payload) {
        state.studentLessons = payload;
    },
    setStudentTexts(state, payload) {
        state.studentTexts = payload;
    },
    setStudentActivitiesReply(state, payload) {
        state.studentActivitiesReply = payload;
    },
    setStudentActivitiesNoReply(state, payload) {
        state.studentActivitiesNoReply = payload;
    },
    setStudentPollsNoReply(state, payload) {
        state.studentPollsNoReply = payload;
    },
    setStudentPollsReply(state, payload) {
        state.studentPollsReply = payload;
    },
}