<template>
  <v-dialog v-model="dialog" persistent max-width="80%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="responsive-button my-4" height="80px" width="90%" color="primary" v-bind="attrs" v-on="on">
        Crear Actividad
      </v-btn>
    </template>

    <v-container fluid>
      <v-card>
        <v-card-title class="primary white--text d-flex justify-space-between ">
          Crear Actividad

          <info-button class="mr-4" title="Información sobre la creacion de una actividad" message="
              <ul>
                <li><strong>Texto:</strong> Seleccione un texto, el cual se ocupara la lectura de la actividad. Solo se pueden seleccionar textos ya creados.</li>
                <li><strong>Incluir Minijuego:</strong> Active esta opción si desea incluir un minijuego en la actividad. Debe seleccionar el tipo de minijuego a continuación.</li>
                <li><strong>Pre-requisito:</strong> (Opcional) Puede seleccionar una actividad previa, esto hace que un estudiante deba tener completa esa activiad, antes de acceder a la activiadad creada.</li>
                <li><strong>Fecha Límite:</strong> Establezca una fecha y hora límite para completar la actividad. Asegúrese de que la fecha límite sea mayor a la fecha actual.</li>
                <li><strong>Preguntas:</strong> Seleccione las preguntas que desea añadir a la actividad. Las preguntas deben estar previamente creadas en el texto seleccionado.</li>
                <li><strong>Descripción:</strong> Proporcione una descripción detallada de la actividad. Esta información será visible para los estudiantes.</li>
                <li><strong>Tiempo Límite:</strong> (Opcional) Establezca un tiempo límite en minutos para completar la actividad.</li>
                <li><strong>Guardar y Cancelar:</strong> Utilice los botones de 'Crear Actividad' para guardar los cambios o 'Cancelar' para descartar cualquier modificación.</li>
              </ul>
            "></info-button>

        </v-card-title>
        <v-form @submit.prevent="submit" ref="form" lazy-validation>
          <v-card-text>
            <v-autocomplete @change="searchQuestions(form.text)" v-model="form.text" :items="texts" item-text="title"
              prepend-icon="mdi-format-title" label="Seleccione un Texto" required return-object>
              <template v-slot:item="{ item }">
                <span>
                  {{ item.title }}
                </span>
                <v-chip color="primary" v-if="item.type==='Minijuego'">
                  <span>{{ item.type }}</span>
                </v-chip>
              </template>
            </v-autocomplete>

            <v-switch v-model="form.minigameEnabled" label="Incluir Minijuego" color="primary"></v-switch>

            <v-autocomplete v-if="form.minigameEnabled" v-model="form.minigame" :items="minigames"
              item-text="name" label="Seleccione un Minijuego" required return-object></v-autocomplete>

            <v-container fluid v-if="form.minigameEnabled">
              <p class="text-justify">
                Info : Si se ingresa el minijuego de las imágenes,  el texto asociado debe contener imágenes relacionadas a los párrafos, en este minijuego se mostraran todas las imágenes que se tienen relacionadas.
              </p>
            </v-container>
            
            <v-autocomplete clearable v-model="form.pre" :items="activities" item-text="description"
              prepend-icon="mdi-format-title" label="Pre-requisito" required return-object>
            </v-autocomplete>
            <date-component @date="form.limit = $event" />

            <v-container fluid v-if="form.text && questions.length > 0">
              <p class="text-justify">
                Seleccione las preguntas que desea añadir:
              </p>
              <v-data-table hide-default-footer disable-pagination v-model="selected" show-select :items="questions"
                item-key="id" :headers="headers">
              </v-data-table>
            </v-container>
            <v-container v-if="questions.length === 0 && form.text">
              <p class="text-justify red--text">
                El texto no posee ninguna pregunta
              </p>
            </v-container>
            <v-textarea class="mt-5" prepend-icon="mdi-format-text-variant" v-model="form.description" auto-grow filled
              color="deep-purple" label="Descripción" rows="3"></v-textarea>
            <v-text-field type="number" v-model="form.limitTime" prepend-icon="mdi-counter"
              label="Tiempo límite minutos (Opcional)"></v-text-field>
          </v-card-text>
          <v-card-actions>
            
            <v-btn color="error" @click="cancel"> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit">
              Crear Actividad
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import DateComponent from "../../UI/DateComponent";
import axios from "axios";
import Swal from "sweetalert2";
import InfoButton from "../../UI/InfoButton.vue";

export default {
  props: {
    lessonId: {
      type: String,
    },
  },
  components: { DateComponent, InfoButton},
  computed: {
    texts() {
      return this.$store.getters["teacher/getTexts"];
    },
    questions() {
      return this.$store.getters["teacher/getQuestions"];
    },
  },
  data() {
    return {
      headers: [],
      activities: [],
      selected: [],
      dialog: false,
      form: {
        lessonId: parseInt(this.lessonId),
        text: null,
        limit: null,
        description: "",
        pre: null,
        limitTime: null,
        minigameEnabled: false,
        minigame: null
      },
      minigames: [
        { name: 'Minijuego de Imágenes', value: 'image_minigame' },
        { name: 'Minijuego de Ordenar Párrafos', value: 'unordered_text'}
      ],
      errors: []
    };
  },
  methods: {
    async getActivities() {
      const response = await axios.get('teacher/activityTeacher/' + this.lessonId);
      const { activity } = response.data;
      this.activities = activity;
    },
    async searchQuestions({ id }) {
      await this.$store.dispatch("teacher/questions", id);
      this.selected = this.questions;
    },
    async getText() {
      await this.$store.dispatch("teacher/texts");
    },
    validateForm() {
      this.errors = [];
      if (!this.form.text) {
        this.errors.push('Debe seleccionar un texto.');
      }
      if (this.form.text && this.form.text.type !== 'Minijuego' && this.selected.length === 0) {
        this.errors.push('Debe seleccionar al menos una pregunta.');
      }
      if (!this.form.description) {
        this.errors.push('Debe proporcionar una descripción.');
      }
      if (!this.form.limit) {
        this.errors.push('Debe proporcionar una fecha límite.');
      } else if (new Date(this.form.limit) <= new Date()) {
        this.errors.push('La fecha límite debe ser mayor a la fecha actual.');
      }
      if (this.form.minigameEnabled){
        if (!this.form.minigame){
          this.errors.push('Debe seleccionar un minijuego.');
        }
      }
    },
    async submit() {
      this.validateForm();
      if (this.errors.length > 0) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          html: this.errors.join('<br>'),
        });
        return;
      }
      await Swal.fire({
        title: "¿Desea crear la actividad?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí!",
        cancelButtonText: "Volver",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let activityId;

          let data = {
            lessonId: this.form.lessonId,
            textId: this.form.text.id,
            date: this.form.limit,
            description: this.form.description,
            pre: this.form.pre ? parseInt(this.form.pre.id) : null,
            limitTime: this.form.limitTime ? parseInt(this.form.limitTime) : null,
            minigame: this.form.minigameEnabled ? this.form.minigame.value : null
          };
          const response = await axios.post("teacher/activity", data);
          const { id } = response.data.activity;
          activityId = id;
          if (this.selected.length > 0) {
            try {
              await axios.post("teacher/activity/question", {
                activityId,
                questionId: this.selected,
              });
            } catch (e) {
              console.log(e);
            }
          }

          this.resetForm();
          await this.getText();
          Swal.fire("Creada", "Actividad creada con éxito", "success");
        }
      });
    },
    cancel() {
      this.resetForm();
      this.dialog = false;
    },
    resetForm() {
      this.selected = [];
      this.form = {
        lessonId: parseInt(this.lessonId),
        text: null,
        limit: null,
        description: "",
        pre: null,
        limitTime: null,
        minigameEnabled: false,
        minigame: null
      };
    },
  },
  async created() {
    await this.getActivities();
    await this.getText();
    this.headers.unshift(
      {
        text: 'Título pregunta',
        align: 'start',
        value: 'title',
        sortable: false,
      },
      {
        text: 'Descripción',
        align: 'start',
        value: 'description',
        sortable: false,
      },
    );
  },
};
</script>


<style scoped>
  @media (max-width: 1150px) {
    .responsive-button {
      font-size: 8px;
    }
  }

  @media (min-width: 1150px) and (max-width: 1280px) {
    .responsive-button {
      font-size: 12px;
    }
  }

  @media (min-width: 1200px) {
    .responsive-button {
      font-size: 13px;
    }
  }
</style>