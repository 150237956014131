var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('loading-component'):_c('div',[_vm._l((_vm.questions),function(question,index){return _c('v-card',{key:index,staticClass:"ma-4",attrs:{"width":"100vw"}},[_c('v-card-title',[_vm._v(" "+_vm._s(index + 1)+") "+_vm._s(question.question.title)+" ("+_vm._s(_vm.calculateScore(question, index))+"pts) ")]),_c('v-card-text',[_c('div',{staticClass:"text--primary"},[(question.question.description.length>0)?_c('p',{staticClass:"text-justify"},[_vm._v(" "+_vm._s(question.question.description)+" ")]):_vm._e(),_c('p',{staticClass:"text-justify"},[_vm._v(" Tiempo que se demoro en la pregunta : "+_vm._s(_vm.getTimeFromId(question.question.id))+" segundos ")])])]),(question.question.type === 0)?_c('v-container',{staticClass:"pa-5",attrs:{"fluid":""}},[_c('div',{staticClass:"light-blue lighten-4",attrs:{"disabled":"","color":"teal"}},[_c('p',{staticClass:"pa-5",attrs:{"text-color":"primary"}},[_vm._v(" Respuesta: "+_vm._s(_vm.questionAnswers[index].answer)+" ")])])]):_vm._e(),(question.question.type === 1)?_c('v-container',{staticClass:"pa-5",attrs:{"fluid":""}},[_c('v-radio-group',_vm._l((question.question.answer),function(alternative,i){return _c('v-radio',{key:i,attrs:{"disabled":"","color":"red","off-icon":_vm.getIcon(alternative, _vm.findReply(_vm.questionAnswers[index]).answer)},scopedSlots:_vm._u([{key:"label",fn:function(){return [(
              alternative.id === _vm.findReply(_vm.questionAnswers[index]).answer.id &&
              _vm.findReply(_vm.questionAnswers[index]).answer.isCorrect
            )?_c('h4',{staticClass:"text-left",staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(alternative.isCorrect ? `${alternative.description} (Respuesta correcta)` : alternative.description)+" ")]):_vm._e(),(
              _vm.findReply(_vm.questionAnswers[index]).answer.id === alternative.id &&
              !_vm.findReply(_vm.questionAnswers[index]).answer.isCorrect
            )?_c('h4',{staticClass:"text-left",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(alternative.isCorrect ? `${alternative.description} (Respuesta correcta)` : alternative.description)+" ")]):_vm._e(),(_vm.findReply(_vm.questionAnswers[index]).answer.id  !== alternative.id)?_c('h4',{staticClass:"text-left",style:({ color: alternative.isCorrect ? 'green' : '' })},[_vm._v(" "+_vm._s(alternative.isCorrect ? `${alternative.description} (Respuesta correcta)` : alternative.description)+" ")]):_vm._e()]},proxy:true}],null,true)})}),1)],1):_vm._e(),(question.question.type === 2)?_c('v-container',{staticClass:"pa-5",attrs:{"fluid":""}},_vm._l((question.question.answer),function(alternative,i){return _c('v-checkbox',{key:i,attrs:{"disabled":"","label":alternative.description,"value":alternative,"off-icon":_vm.getIconMultiple(alternative, _vm.questionAnswers[index])},scopedSlots:_vm._u([{key:"label",fn:function(){return [(
            _vm.questionAnswers[index].answer.some(
              (val) => val.id === alternative.id
            ) && alternative.isCorrect
          )?_c('h4',{staticClass:"text-left",staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(alternative.description)+" (Respuesta Correcta) ")]):(
            _vm.questionAnswers[index].answer.some(
              (val) => val.id === alternative.id
            ) && !alternative.isCorrect
          )?_c('h4',{staticClass:"text-left",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(alternative.description)+" ")]):(
            _vm.questionAnswers[index].answer.find(
              (val) => val.id !== alternative.id
            )
          )?_c('h4',{staticClass:"text-left",style:({ color: alternative.isCorrect ? 'green' : '' })},[_vm._v(" "+_vm._s(alternative.isCorrect ? `${alternative.description} (Respuesta correcta)` : alternative.description)+" ")]):_vm._e()]},proxy:true}],null,true)})}),1):_vm._e()],1)}),(_vm.questions.length === 0)?_c('v-card',[_vm._v(" Texto no posee actividad. ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }