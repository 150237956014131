<template>
    <div>
        <v-tabs v-model="tab" class="mt-7">
            <v-tab v-for="(_, i) in replies" :href="`#${i}`" :key="i">Pregunta {{ i + 1 }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item v-for="(question, index) in replies" :key="index" :value="`${index}`">
                <v-card :key="index" class="mt-4" width="100vw" height="100vh">
                    <v-card-text>
                        <v-card-title class="py-0 mx-0">
                            <p>Puntaje total: {{ totalPoints }}/60</p>
                        </v-card-title>
                        <v-card-title class="py-0 mx-0">
                            <p>Puntaje total por tipo ({{ question.question.type === 1 ? 'Interés/Valor' :
                                    question.question.type === 2 ?
                                        'Autoeficacia' : 'Creencias de superación personal'
                            }}): {{ pointsPerType[question.question.type] }}</p>
                        </v-card-title>
                        <v-card-title class="py-0 mx-0">
                            <p>Puntaje pregunta: {{ question.answer.points }}/3</p>
                        </v-card-title>
                        <v-card-title class="py-0 mx-0">
                            <p>
                                {{ question.question.description }}
                            </p>
                        </v-card-title>

                        <v-container fluid>
                            <v-radio-group>
                                <v-radio disabled v-for="(alternative, i) in answers" :key="i"
                                    :label="alternative.description" :value="alternative"
                                    :off-icon="alternative.id === question.answerId ? 'mdi-check-circle' : 'mdi-checkbox-blank-circle-outline'">
                                    <template v-slot:label>
                                        <h4 v-if="alternative.id === question.answerId" class="text-left"
                                            style="color: green">
                                            {{ alternative.description }}
                                        </h4>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn v-if="tab !== '0'" @click="tab = `${parseInt(tab) - 1}`" text
                            color="deep-purple accent-4">
                            Anterior
                        </v-btn>
                        <v-btn v-if="tab !== `${replies.length - 1}`" @click="tab = `${parseInt(tab) + 1}`" text
                            color="deep-purple accent-4">
                            Siguiente
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            totalPoints: null,
            tab: null,
            student: [],
            replies: [],
            answers: [],
            pointsPerType: []
        }
    },
    methods: {

    },
    async created() {
        const idActivity = this.$route.params.idActividad;
        const idStudent = this.$route.params.idEstudiante;
        const response = await axios.get('teacher/poll_reply/' + idStudent + '/' + idActivity);
        this.student = response.data.reply.student;
        this.replies = JSON.parse(response.data.reply.answer);
        this.answers = response.data.reply.activity.poll.answer;
        this.totalPoints = response.data.reply.points;
        this.pointsPerType = this.replies.reduce((acc, el) => {
            if (!acc[el.question.type]) {
                acc[el.question.type] = el.answer.points
            } else {
                acc[el.question.type] += el.answer.points
            }
            return acc;
        }, {});
    }
}
</script>