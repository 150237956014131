<template>
    <v-container  fluid>
  
      <v-row fluid class="mt-8 justify-center" height="400px">
        <v-card outlined style="border: 0.2px solid #333;" width="70%" class="p-3 m-5 mr-5" >
          <add-teacher :bottom-style="false" />
        </v-card> 
  
        <v-card outlined style="border: 0.2px solid #333;" center width="20%">
          <v-card-actions >
            <v-col class="justify-center align-center pt-13">
              <v-row class="justify-center align-center ">
                <edit-user></edit-user>                
              </v-row>
              <v-row class="justify-center align-center">
                <AddStudent></AddStudent>
              </v-row>
            </v-col>
          </v-card-actions>
        </v-card>
  
      </v-row>
      
  
      <v-row  justify="center">
        <v-col class="d-flex justify-center align-center">
          <v-progress-circular v-if="loading" size="200" :width="3" color="primary" indeterminate></v-progress-circular>
        </v-col>
      </v-row>
  
    </v-container>
  </template>
  
  <script>
  
  import AddTeacher from '../../../components/teacher/forms/AddTeacher.vue';
  import EditUser from '../../../components/teacher/forms/EditUser';
  import AddStudent from '../../../components/teacher/forms/AddStudent.vue';

  export default {
  
    data() {
      return {
        loading: null,
      }
    },
    components: { AddTeacher, EditUser, AddStudent},
    computed: {
      
    },
  
    methods: {
      
    },
    
  }
  </script>
