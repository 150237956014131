<template>
  <div class="mt-4 pa-4">
    <div class="primary white--text my-2 rounded-lg">
      <h1 class="ml-4 pt-2 my-1 ">{{ poll.title }}</h1>
      <h3 v-if="poll.description" class="mx-4 py-2">Descripción: {{ poll.description }}</h3>
    </div>

    <div v-for="(section, sectionIndex) in sections" :key="sectionIndex" class="mb-4">
      <v-card class="my-4 pa-4" outlined>
        <v-card-title style="background-color: rgba(63, 81, 181, 0.8); color: black;">{{ section.title }}</v-card-title>
        <v-divider class="my-2"></v-divider>
        <div v-for="(question, index) in section.questions" :key="index" class="mb-4">
          <v-text-field v-model="question.description" label="Pregunta" readonly class="mx-4"></v-text-field>
          <v-container v-if="question.type === 2" class="px-4">
            <v-radio-group v-model="question.answer" row>
              <v-radio v-for="(answer, i) in getSectionAnswersYESNO(section.title,question)" :key="i" :label="`${answer.description} (${answer.points} pts)`" :value="answer.description" disabled></v-radio>
            </v-radio-group>
          </v-container>
          <v-container v-else-if="question.type === 1" class="px-4">
            <v-radio-group row>
              <v-radio v-for="(answer, i) in getSectionAnswers(section.title)" :key="i" :label="`${answer.description} (${answer.points} pts)`" :value="answer.description" disabled></v-radio>
            </v-radio-group>
          </v-container>
          <v-container v-else-if="question.type === 3" class="px-4">
            <v-textarea label="Respuesta de Desarrollo" auto-grow disabled></v-textarea>
          </v-container>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      pollId: null,
      poll: { title: '', description: '', questions: [] },
      sections: []
    }
  },
  methods: {
    async getPoll() {
      const response = await axios.get('teacher/poll/' + this.pollId);
      const pollData = response.data.poll;
      const sectionsMap = {};

      // Organizar preguntas y respuestas por sección
      pollData.question.forEach(question => {
        const section = question.section || 'Sin Sección';
        if (!sectionsMap[section]) {
          sectionsMap[section] = {
            title: section,
            questions: [],
            answers: []
          };
        }
        sectionsMap[section].questions.push(question);
      });

      pollData.answer.forEach(answer => {
        const section = answer.section || 'Sin Sección';
        if (sectionsMap[section]) {
          sectionsMap[section].answers.push(answer);
        }
      });

      this.poll = {
        title: pollData.title,
        description: pollData.description,
      };
      this.sections = Object.values(sectionsMap);
    },
    getSectionAnswers(sectionTitle) {
      return this.sections.find(section => section.title === sectionTitle)?.answers.filter(answer => answer.description !== 'Sí' && answer.description !== 'No') || [];
    },
    getSectionAnswersYESNO(sectionTitle, question) {
      const answers = this.sections.find(section => section.title === sectionTitle)?.answers.filter(answer => answer.description === 'Sí' || answer.description === 'No') || [];
      if (answers.length === 0) {
        return [
          {  description: 'Sí', id: `yes_${question.id}`, points: '0' },
          {  description: 'No', id: `no_${question.id}`, points: '0' }
        ];
      }
      return answers;
    },
  },
  async created() {
    this.pollId = this.$route.params.id;
    await this.getPoll();
  }
}
</script>

<style scoped>
.v-card-title {
  padding: 16px;
  margin-bottom: 8px;
}

.v-card-subtitle {
  padding-left: 16px;
  padding-right: 16px;
}

.pa-4 {
  padding: 16px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mx-4 {
  margin-left: 16px;
  margin-right: 16px;
}

.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.px-4 {
  padding-left: 16px;
  padding-right: 16px;
}
</style>
