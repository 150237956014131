<template>
  <v-dialog persistent v-model="dialog" max-width="80%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="white--text" color="deep-purple accent-4" v-bind="attrs" v-on="on">
        Agregar por medio de la lista actual
      </v-btn>
    </template>

    <v-card fluid>
      <v-card-title class="primary white--text d-flex justify-space-between">
        Añadir Estudiantes
        <info-button class="mr-4" title="Información sobre añadir estudiantes a una asignatura" message="
              <ul>
                <li><strong> Aquí solamente se muestran sólo los estudiantes registrados en la plataforma. </strong></li>
                <li><strong>Añadir estudiantes:</strong> Seleccione todos los estudiantes que desea agregar a la asignatura y haga clic en el botón 'Agregar Estudiantes'.</li>
                <li><strong>Cancelar:</strong> Haga clic en el botón 'Cancelar' para cerrar el modal sin agregar estudiantes.</li>
              </ul>
            "></info-button>
      </v-card-title>
      <v-card-text>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar Estudiantes" single-line hide-details>
        </v-text-field>
      </v-card-text>
      <v-data-table height="300px" fixed-header :search="search" v-model="selected" :headers="headers" :items="students" :single-select="singleSelect" item-key="id" show-select class="elevation-1 mb-1">
      </v-data-table>
      <v-card-actions>
        <v-btn color="error" @click="cancel"> Cancelar </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-2" @click="confirmAddStudent" :disabled="selected.length === 0">Agregar Estudiantes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import InfoButton from "../../../../components/UI/InfoButton.vue";
export default {
  components: { InfoButton },
  data() {
    return {
      search: '',
      students: [],
      singleSelect: false,
      selected: [],
      dialog: false,
      headers: [
        {
          text: 'Nombre Estudiante',
          align: 'start',
          value: 'name',
        },
        { text: 'Apellido Paterno', value: 'lastName' },
        { text: 'Apellido Materno', value: 'secondLastName' },
        { text: 'email', value: 'email' },
        { button: 'agregar' }
      ],
    }
  },
  methods: {
    async getStudents() {
      const id = this.$route.params.id;

      const response = await axios.get('teacher/students/' + id);
      const { student } = response.data;
      this.students = student;
    },

    async confirmAddStudent() {
      const result = await Swal.fire({
        title: '¿Desea agregar estos estudiantes a la asignatura?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, agregar',
        cancelButtonText: 'Cancelar'
      });

      if (result.isConfirmed) {
        this.addStudent();
      }
    },

    async addStudent() {
      let studentsIds = [];
      this.selected.map(data => studentsIds.push(data.id));

      let data = {
        lessonId: this.$route.params.id,
        studentId: studentsIds,
      }

      try {
        await axios.post('teacher/student_lesson', data);
        this.selected = [];
        await this.getStudents();
        this.$emit('student-saved'); 
        this.dialog = false;
        Swal.fire('Agregado', 'Los estudiantes se agregaron con éxito.', 'success');
      } catch (error) {
        Swal.fire('Error', 'Hubo un problema al agregar los estudiantes. Inténtelo nuevamente.', 'error');
      }
    },
    cancel() {
      this.dialog = false;
    },
  },
  created() {
    this.getStudents();
  }
}
</script>