import axios from "axios";

export default {
    async lessonsStudent(context) {
        try {
            let response = await axios.get('student/lesson');
            await context.commit('setStudentLessons', response.data.lessons);
        } catch (e) {
            console.log(e)
        }

    },
    async textsStudent(context) {
        try {
            let response = await axios.get('student/text');
            await context.commit('setStudentTexts', response.data.texts);
        } catch (e) {
            console.log(e)
        }
    },
    async activitiesStudent(context, data) {

        try {
            const response = await axios.get('student/activity/finishAndPending/' + data);
            await context.commit('setStudentActivitiesReply', response.data.reply);
            await context.commit('setStudentActivitiesNoReply', response.data.noReply);
        } catch (e) {
            console.log(e)
        }
    },
    async pollsStudent(context, data) {

        try {
            const response = await axios.get('student/poll_activity/getFinishAndPending/' + data);
            await context.commit('setStudentPollsReply', response.data.reply);
            await context.commit('setStudentPollsNoReply', response.data.noReply);
        } catch (e) {
            console.log(e)
        }
    },
}
